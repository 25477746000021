.wrapper {
    width: 100%;
    position: relative;
}

.wrapper * {
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
}

.input-selector {
    position: relative;
    color: #303235;
    box-sizing: content-box;
    display: flex;
    align-items: center;
    width: 100%;
    .input {
        width: 100%;
        min-height: 33.5px;
        height: auto;
        font-size: 14px;
        line-height: 1.5;
        color: #303235 !important;
        font-weight: 500;
        padding: 5px 40px 5px 10px;
        border-radius: 5px;
        border: 1px solid #e8edf2;
        background: #fff;
        transition: all 0.3s ease-in-out;

        &:focus {
            border-color: #a8a8a8;
            outline: none;
        }
        &::placeholder {
            color: #868d94;
        }
    }

    .icon {
        display: inline-block;
        margin-left: -24px;
        transition: all 0.3s ease-in-out;
        &.active {
            transform: rotate(180deg);
        }
    }
}
.active .icon {
    transform: rotate(180deg);
}

.active .options {
    display: block;
}

.options {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: #fff;
    border: 1px solid #e8edf2;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    z-index: 9999;
    max-height: 200px;
    overflow-y: auto;
    padding: 10px 0;
    width: 100%;
    display: none;
}

.options.active {
    display: block !important;
}

.option {
    padding: 10px 20px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    display: flex;
    align-items: center;

    .text {
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        color: #303235;
    }

    &:hover {
        background: #f5f7f9;
    }

    &.active {
        background: #a9ffbb;
    }

    &.selected {
        background: #f5f7f9;
    }

    .icon {
        margin-right: 10px;
    }
}
