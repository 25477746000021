.wrapper {
    box-sizing: border-box;
    position: relative;
    padding: 24px 5px;
    z-index: 2;
    margin: 0 auto;
}

@media screen and (max-width: 768px) {
    .img {
        height: 100px !important;
    }

    .content {
        padding: 0 5px !important;
    }
}

@media screen and (min-width: 768px) {
    .wrapper {
        width: 750px;
    }

    .img {
        height: 130px !important;
    }
}

@media screen and (min-width: 992px) {
    .wrapper {
        width: 970px;
    }
}

@media screen and (min-width: 1200px) {
    .wrapper {
        width: 1170px;
    }

    .img {
        border-radius: 20px !important;
        height: 207px !important;
    }
}

.slide {
    width: calc(100%) !important;
}

.slider > div {
    border-radius: 10px;
}

.content {
    display: block;
    padding: 0 10px;
}

.img {
    display: inline-block;
    object-fit: cover;
    width: 100%;
    border-radius: 4px;
    height: 200px;
}

.btn {
    position: absolute;
    align-items: center;
    border-radius: 50%;
    color: #00b14f !important;
    cursor: pointer;
    display: flex;
    justify-content: center;
    width: 40px;
    height: 40px;
    background: #fff;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.08);
}

.btn-left {
    left: 10px;
    top: 50%;
    transform: translate(-50%, -50%);
}

.btn-right {
    right: 10px;
    top: 50%;
    transform: translate(50%, -50%);
}

.btn:hover {
    background: #00b14f;
    color: #fff;

    .icon {
        color: #fff;
    }
}

.icon {
    font-size: 20px;
}
