.toast {
    top: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 1060 !important;
    pointer-events: none;
    min-width: 170px;
    transition: all 1s ease-in-out;
}
