.wrapper {
    margin: 16px 18px;
    border: 1px solid #e8edf2;
    padding: 18px;
    border-radius: 4px;
}

.container {
}

.auth-level-title {
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    padding: 16px 16px 0 16px;

    .auth-level-value {
        color: #00b14f !important;
    }
}

.auth-level-content {
    padding: 16px;

    .auth-level-content-item {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
    }

    .auth-level-content-icon {
        margin-right: 16px;

        .icon {
            width: 40px;
        }
    }

    .auth-level-content-text {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.5;
        color: #303235;
    }

    .auth-level-content-text-value {
        color: #00b14f !important;
    }
}

.auth-level-button {
    padding: 16px;
    display: flex;
    justify-content: flex-end;
    .btn {
        border: 1px solid transparent;
        background-color: #00b14f;
        border-color: #00b14f;
        font-size: 14px;
        line-height: 1.5;
        margin-right: 16px;
        white-space: nowrap;
        border-radius: 5px;
        padding: 8.5px 14px;
        transition: all 0.15s ease-in-out;
        color: #fff;

        .btn-link {
            width: 100%;
            height: 100%;
        }

        &:hover {
            background-color: #00652d;
            border-color: #00652d;
        }
    }

    .link {
        color: #00b14f;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.5;
        transition: all 0.15s ease-in-out;
        padding: 8.5px 14px;
        border: 1px solid transparent;
        border-color: #00b14f;
        border-radius: 5px;

        &:hover {
            background: #00b14f;
            color: #fff;
        }
    }
}
