.wrapper {
}

.title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: #212f3f;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    margin-bottom: 8px;
    overflow: hidden;
    width: fit-content;
}

.job-info {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 2px 0;
}

.salary {
    display: flex;
    flex-shrink: 0;
    .text {
        color: #11ad39;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
    }
}

.address {
    display: flex;
    .text {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        color: #263a4d;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.12px;
        line-height: 16px;
        overflow: hidden;
    }
}
.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #00b14f;
    font-size: 12px;
    font-weight: 900;
    height: 16px;
    width: 16px;
    line-height: 16px;
    margin-right: 6px;
}
