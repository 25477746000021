.wrapper * {
    box-sizing: content-box !important;
}

@media screen and (max-width: 768px) {
    .location {
        display: none !important;
    }

    .button-search {
        .button {
            border-radius: 4px !important;
            font-size: 16px !important;
            font-weight: 600 !important;
            line-height: 24px !important;
            padding: 4px 0px !important;
        }
    }
}

@media screen and (max-width: 1200px) {
    .location {
        .text {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }
}

.form {
    margin-bottom: 17px;
}

.container {
    position: relative;
    z-index: 4;
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    align-items: center;
    border-radius: 12px;
    box-shadow: 0 8px 16px 0 rgba(1, 18, 34, 0.04);
    gap: 4px;
    padding: 8px 8px 8px 12px;
    background-color: #fff;
}

.search {
    height: 40px;
    align-items: center;
    display: flex;
    position: relative;
    width: 100%;
    .icon-search {
        position: absolute;
        color: #6f7882;
        font-size: 18px;
        align-items: center;
        height: 24px;
        left: 0;
        padding: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 24px;
        z-index: 1;
    }

    .icon-search:hover {
        cursor: pointer;
        color: #00b14f;
    }

    .input {
        border: none;
        border-radius: 6px !important;
        font-size: 14px;
        font-weight: 500;
        height: 100%;
        outline: none;
        padding: 8px 19px 8px 38px;
        width: 100%;
    }
}

.location {
    height: 40px;
    width: 100%;
    position: relative;
    vertical-align: middle;
    display: inline-block;
    margin: 0;
    cursor: pointer;

    .icon-location {
        color: #6f7882;
        font-size: 16px;
        align-items: center;
        display: flex;
        height: 24px;
        left: 4px;
        padding: 0 8px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 24px;
        z-index: 1;
    }

    .select-hidden {
        align-items: center;
        background: #fff 0 0 no-repeat;
        border-radius: 6px !important;
        color: #172530;
        display: flex !important;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        text-indent: 1px;
        text-overflow: '';
        border: 0 !important;
        clip: rect(0 0 0 0) !important;
        height: 1px !important;
        margin: -1px !important;
        overflow: hidden !important;
        padding: 0 !important;
        position: absolute !important;
        width: 1px !important;
    }

    .select-show {
        height: 40px !important;
        width: 100% !important;
        margin: 0;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 172px;
    }

    .selection-2 {
        align-items: center;
        background: #fff 0 0 no-repeat padding-box;
        border: none;
        border-radius: 6px !important;
        display: flex !important;
        height: 100% !important;
        padding: 19px 19px 19px 28px;
    }
    .text {
        color: #172530;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        display: block;
        padding-left: 8px;
        padding-right: 20px;
    }
    .button {
        position: absolute;
        right: 4px;
        top: 0;
        height: 100%;
    }
    .icon {
        font-size: 24px;
        color: #6f7882;
        transition: all 0.3s ease;
    }

    .icon.rotate {
        color: #00b14f;
        transform: rotate(180deg);
    }

    .select-dropdown-container {
        display: none;
        position: absolute;
        top: 40px;
        left: 0;
        .select-dropdown {
            width: 200px;
            background: #fff;
            border-radius: 6px;
            box-shadow: 0 1px 16px rgba(0, 0, 0, 0.08);
            overflow: hidden;
            z-index: 2;
        }

        .select-dropdown-search {
            padding: 16px;
        }

        .input {
            background: #fff;
            border: 1px solid #e9eaec;
            outline: none;
            border-radius: 6px;
            padding: 9px 8px 9px 8px;
            width: calc(100% - 8px);
            color: rgb(51, 51, 51);
            font-size: 14px;
            line-height: 20px;
        }

        .select-results {
            max-height: 230px;
            overflow: auto;
        }

        .search-options {
            color: #172530;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            padding: 12px 12px 12px 16px;
            max-height: 230px !important;
            overflow: hidden;
        }

        .search-options:hover {
            background: #f4f5f5;
            cursor: pointer;
        }

        .select-option {
            color: #172530;
            font-size: 14px;
            line-height: 20px;
            padding: 12px 12px 12px 16px;
            position: relative;

            .icon-check {
                position: absolute;
                right: 16px;
                top: 50%;
                transform: translateY(-50%);
                color: #00b14f;
                font-size: 22px;
            }
        }

        .select-option:hover {
            background: #f4f5f5;
            cursor: pointer;
        }

        .selected {
            color: #00b14f;
            font-weight: 600;
        }
    }

    .select-dropdown-container.display {
        display: block;
    }
}

.location::before {
    background: #dee0e2;
    border-radius: 100px;
    content: '';
    position: absolute;
    top: 0;
    left: -1px;
    height: 32px;
    width: 1px;
    background: #e9eaec;
    transform: 50%;
}

.button-search {
    .button {
        background: #00b14f;
        border-radius: 4px;
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        height: 40px;
        line-height: 22px;
        padding: 0 12px;
        width: 110px;
    }

    &:hover .button {
        background: #009643;
    }
}

.search-job {
    display: none;
    align-items: flex-start;
    align-self: stretch;
    background: #fff;
    border-radius: 12px;
    box-shadow: 2px 4px 16px 0 rgba(1, 18, 34, 0.1);
    flex-direction: column;
    left: 0;
    max-height: calc(100vh - 330px);
    overflow-x: hidden;
    overflow-y: auto;
    padding: 8px 16px;
    position: absolute;
    right: 0;
    top: calc(100% + 4px);

    .search-label {
        display: flex;
        align-self: stretch;
        gap: 10px;
        justify-content: space-between;
        padding: 8px 0;
    }

    .title {
        color: #212f3f;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        letter-spacing: -0.16px;
        line-height: 24px;
    }

    .search-item {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .item {
        align-items: center;
        background: #fff;
        border-radius: 4px;
        cursor: pointer;
        display: flex;
        gap: 10px;
        padding: 6px 8px 6px 4px;
        width: 100%;
    }
    .icon {
        font-size: 20px;
        color: #7f878f;
    }

    .text {
        color: #212f3f;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        letter-spacing: 0.14px;
        line-height: 22px;
    }
}

.search-job.display {
    display: flex;
}
