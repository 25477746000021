.wrapper {
    margin: 8px 0;
    min-width: 368px;

    &:hover .control {
        display: block;
    }
}

@media screen and (max-width: 768px) {
    .wrapper {
        min-width: 300px !important;
    }
}

.link {
    align-items: center;
    color: #212f3f;
    display: flex;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    padding: 14px 8px;
    background: #f4f5f5;
    border-radius: 6px;

    &:hover {
        background: hsla(0, 2%, 91%, 0.722) !important;
        cursor: pointer;
    }

    &:hover .text {
        color: #00b14f !important;
    }
}

.icon {
    color: #00b14f !important;
    font-size: 20px;
    padding-right: 8px;
    width: 30px;
}

.label {
    align-items: center;
    color: #212f3f;
    display: flex;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    .text {
        font-size: 14px;
        font-weight: 500;
    }
}

.control {
    display: none;
    position: absolute;
    right: 18px;
    margin-top: 4px;

    .icon {
        font-size: 16px;
    }
}

.badge {
    border-radius: 3px;
    color: #fff;
    display: inline-block;
    font-size: 12px;
    line-height: 16px;
    margin: 0 4px;
    padding: 2px 4px;
    text-transform: uppercase;
}

.badge-1 {
    background: #dc2f2f;
}

.badge-2 {
    background: #f70;
}

.badge-3 {
    background: #00b14f;
}

.icon-lg {
    font-size: 16px;
}

.add-hr {
    margin-bottom: 10px;
}

.add-hr::after {
    content: '';
    display: block;
    height: 1px;
    background: #e9eaec;
    margin-top: 10px;
}
