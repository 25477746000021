.wrapper {
    display: flex;
    flex-direction: column;
    width: calc(100% - 15px);
    margin: 0 auto 24px;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .wrapper {
        width: calc(750px - 15px);
    }

    .slider {
        width: 100%;
    }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .wrapper {
        width: calc(970px - 15px);
    }

    .slider {
        width: 100%;
    }
}

@media screen and (min-width: 1200px) {
    .wrapper {
        width: calc(1170px - 15px);
    }

    .slider {
        width: 100%;
    }
}

.top-company {
    display: flex;
    padding: 19px 10px;
    .title {
        flex: 1;
        color: #00b14f;
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        margin: 0;
        float: left;
    }
}

.box-tool {
    display: flex;
    gap: 10px;
    align-items: center;
    box-sizing: border-box;

    .btn {
        align-items: center;
        aspect-ratio: 1/1;
        border: 1px solid #00b14f;
        border-radius: 50%;
        color: #00b14f !important;
        cursor: pointer;
        display: flex;
        justify-content: center;
        width: 32px;
    }

    .btn:hover {
        background: #00b14f;
        color: #fff;

        .icon {
            color: #fff;
        }
    }

    .icon {
        font-size: 20px;
    }
}
.slider {
    // width: calc(1155px);
    margin: 0 auto;
    width: 100%;

    .content {
        box-sizing: border-box;
        align-items: center;
        background: #fff;
        border: 1px solid #dee0e2;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        min-height: 184px;
        padding: 16px;
        width: 270px;
        // margin: 0 10px;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

        &:hover {
            border: 1px solid #33c172;
            box-shadow: 0 10px 30px rgba(14, 166, 59, 0.2);
        }
    }

    .label {
        box-sizing: border-box;
        border-radius: 3px;
        color: #fff;
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
        margin-right: auto;
        min-height: 25px;
        padding: 3px 20px 3px 6px;
        position: relative;
        text-transform: uppercase;
        max-width: 100%;
        margin-bottom: 5px;
    }

    .label::before {
        background: #fff;
        border-top-right-radius: 5px;
        content: '';
        height: 21px;
        position: absolute;
        right: -10px;
        top: 50%;
        transform: translateY(-50%) rotate(-45deg);
        width: 21px;
    }

    .vnr500 {
        background: linear-gradient(90deg, #fd7222, #edab28);
    }

    .fast500 {
        background: #243a76;
    }

    .profit500 {
        background: #dc2f2f;
    }

    .v1000 {
        background: #e48924;
    }
    .img {
        height: 96px;
        object-fit: contain;
        width: 100%;
    }

    .title {
        white-space: normal;
        width: 100%;
        color: #212f3f;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        margin: 8px 0 0;
        min-height: 40px;
        text-align: center;
        text-transform: uppercase;
        // overflow: hidden;
        // text-overflow: ellipsis;
    }

    @supports (-webkit-line-clamp: 2) {
        .title {
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: normal;
        }
    }
}

.slider .slide {
    display: flex !important;
    justify-content: center !important;
}
