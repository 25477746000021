.wrapper {
}

@media screen and (max-width: 768px) {
    .form-group-flex {
        flex-direction: column !important;
        gap: 0 !important;
        margin-bottom: 16px !important;
    }

    .work-location {
        gap: 16px !important;
    }
}

.form-group {
    margin: 16px 0;
}

.form-group-title {
    margin-bottom: 8px;
}

.form-group-title-text {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    display: flex;
    line-height: 1.5;

    &::before {
        content: '';
        width: 4px;
        height: 30px;
        background: #00b14f;
        font-size: 16px;
        margin-right: 5px;
    }
}

.form-group-input-label {
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 7px;
    height: 23px;
    width: 100%;
    float: left;
    font-weight: 600;

    .note {
        color: #da4538 !important;
    }
}

.input {
    padding: 10px 12px;
    padding-left: 40px;
    height: 40px;
    color: #000;
    display: block;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e8edf2;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:focus {
        border-color: #00b14f;
        outline: 0;
        box-shadow: 0 0 0 0.1rem rgba(0, 177, 79, 0.25);
    }
}

.invalid-feedback {
    font-size: 14px;
    min-height: 22px;
    width: 100%;
    margin-top: 0.25rem;
    color: #dc3545;
    font-weight: 500;
    .invalid-feedback-text {
        color: #dc3545;
    }
}

.warning {
    font-size: 12px;
    color: #da4538;
    font-weight: 300;
    margin: 4px 0 8px 0;
}

.input-container {
    position: relative;
}

.icon {
    position: absolute;
    top: 50%;
    transform: translateY(6px);
    left: 16px;
    color: #00b14f;
}

.eye {
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(6px);
    right: 30px;
    font-size: 18px;
}

.form-group-input {
    width: 100%;

    .disable ~ button {
        display: none !important;
        transform: unset !important;
    }
    .disable.header-select {
        pointer-events: none !important;
        background: #e8edf2 !important;
    }
}

.form-group-flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 95px;
}

.form-group-gender {
    width: 100%;
    display: flex;
    flex-direction: column;
    .group-gender {
        display: flex;
    }

    .input-radio {
        width: 20px;
        height: 20px;
    }

    .label-radio-text {
        padding: 0 16px 0 8px;
    }
}

.header-select {
    padding: 10px 12px;
    padding-left: 40px;
    align-items: center;
    background-color: #fff;
    border: 1px solid #e8edf2;
    border-radius: 0.25rem !important;
    width: 100%;
    line-height: 1.5;
    color: #000;
    font-size: 14px;
    position: relative;
    cursor: pointer;

    .container-select {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
    }

    .icon {
        top: unset !important;
        transform: unset !important;
        left: 14px;
        color: #00b14f;
        font-size: 16px;
    }

    .result {
        color: #172530;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        padding-left: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.ul-select {
    overflow-y: auto;
    max-height: 230px !important;

    .item {
        color: #172530;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        padding: 12px 12px 12px 16px;
        position: relative;
        cursor: pointer;
    }

    .text {
        font-size: 14px;
        font-weight: 500;
        color: #172530;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .icon-check {
        font-size: 20px;
        color: #00b14f;
        position: absolute;
        right: 12px;
    }
    .item:hover {
        background: #f4f5f5;
    }

    .item.active {
        background: #00b14f;
    }

    .item.active .text {
        color: #fff !important;
        font-weight: 600;
    }
}

.icon-care {
    box-sizing: content-box !important;
    margin-right: 12px;
    padding-left: 12px;
}

.input-skype {
    padding-left: 12px;
}

.form-group-checkbox {
    height: 60px;
    display: flex;
    padding-left: 0;
    flex-direction: row !important;
    align-items: center;
    margin-bottom: 0;

    .input-checkbox {
        display: none !important;
    }

    .label-checkbox::before {
        background: #fff;
        border: 1px solid #646d79;
        border-radius: 5px;
        content: '';
        display: block;
        height: 14px;
        margin-right: 8px;
        width: 14px;
    }

    .input-checkbox:checked + .label-checkbox::before {
        background: #00b14f;
        border-color: #00b14f;
        color: #fff;
        content: '\2713';
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        line-height: 1.5;
    }

    .label-checkbox-text {
        height: 23px;
        font-weight: 300;
        font-size: 14px;
        line-height: 1.5;
        a {
            font-weight: 600;
            color: #00b14f !important;
        }
    }
}

.submit {
    display: flex;
    align-content: center;
    justify-content: center;
    margin: 0;
    .btn-submit {
        font-size: 14px;
        min-width: 100px;
        color: #fff;
        background-color: #00b14f;
        border-color: #00b14f;
        width: 100% !important;
        line-height: 1.5;
        padding: 8px 14px;
        border-radius: 0.25rem;
        font-weight: 400;
    }
    .disable {
        pointer-events: none;
        background: #e8edf2;
        color: rgba(48, 50, 53, 0.3);
    }
}

.option {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 0;
    .option-text {
        padding: 8px 0;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.5;
    }
    .option-link {
        font-size: 14px;
        font-weight: 600;
        color: #00b14f;

        &:hover {
            text-decoration: underline;
        }
    }
}

.icon-warning {
    position: absolute;
    top: 50%;
    transform: translateY(6px);
    right: 8px;
    font-size: 16px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

.auth-footer {
    .copy-right {
        font-size: 12px;
        color: #00b14f !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
