.wrapper {
    position: relative;

    img {
        width: 48px;
        height: 48px;
        border-radius: 10px;
        object-fit: cover;
    }

    .file {
        width: 100px;
        height: 48px;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #e4e6eb;
        padding: 5px;

        .name {
            font-size: 12px;
            color: #000;
            font-weight: 500;
            text-align: center;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    } 
    
    .remove {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(50%, -50%);
        cursor: pointer;
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        font-size: 12px;
        font-weight: bold;
        background-color: #e4e6eb;
        color: #353535;
        border: 1px solid #bebebe;

        &:hover {
            background-color: #bebebe;
        }

        &:disabled {
            cursor: unset !important;
            background-color: #e4e6eb !important;
        }
    }
}