.wrapper {
    display: none;
    width: 100%;
}

.container {
    background: #fff;
    border-radius: 8px;
    padding: 20px 0 0 0;
    width: 100%;
}

.title {
    color: #212f3f;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -0.16px;
    line-height: 24px;
    margin-bottom: 16px;
    margin-top: 0;
}

.content {
    grid-column-gap: 20px;
    display: grid;
    grid-template-columns: 50% 50%;

    .item {
        align-items: center;
        display: flex;
        gap: 12px;
        margin-bottom: 16px;
    }

    .icon-container {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #00b14f;
        border-radius: 30px;
        gap: 10px;
        height: 28px;
        // padding: 10px;
        width: 28px;
    }

    .icon {
        width: 18px;
        height: 18px;
    }

    .label {
        color: #4d5965;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
    }

    .value {
        color: #212f3f;
        font-size: 14px;
        font-weight: 600;
        line-height: 22px;
    }
}

@media screen and (max-width: 767px) {
    .wrapper {
        display: block !important;
    }

    .container {
        padding: 20px 16px 0 16px !important;
    }
}
