.wrapper {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: end;
    padding: 11px 0;
    gap: 24px;
    width: 100%;
    height: 100%;
    z-index: 999;
}

.wrapper.small {
    transform: scale(0.8);
}

.col:first-child {
    height: 100%;
}

.col:nth-child(2) {
    height: 60%;
}

.col:nth-child(3) {
    height: 80%;
}

.col:nth-child(4) {
    height: 90%;
}

.col:nth-child(5) {
    height: 100%;
}

.col:nth-child(6) {
    height: 50%;
}
