.wrapper{
    margin-bottom: 16px;

}

.container{
    border: 1px solid #f4f4f4;
    border-radius: 5px;
    box-shadow: -1px 1px 6px rgba(0, 0, 0, .05);
    box-sizing: border-box;
    padding: 16px;
    transition: all 0.3s ease-in-out;
    display: flex;
    &:hover{
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .07) !important;
        }
}

.company-logo-container{
    .company-logo{
        background: #fff;
        border: 1px solid #f7f7f7;
        border-radius: 5px;
        filter: drop-shadow(-1px 1px 6px rgba(0, 0, 0, .05));
        height: 80px;
        width: 80px;
        object-fit: contain;
    
    }
}


.company-info{
    margin-left: 20px;

    .company-info-name{
        margin-bottom: 10px;

        .company-info-name-text{
            color: #333;
            font-size: 15px;

            &:hover{
                text-decoration: underline;
                cursor: pointer;
            }
        }

        .company-active-job{
            font-size: 15px;
        }

        .company-active-job-icon{
            color: #00b14f;
            font-size: 24px;
            transform: translateY(8px);
        }

    }

    .company-info-icon-container{
        background: gray;
        border-radius: 8px;
        margin-right: 15px;
        min-width: 16px;
        min-height: 16px;
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .icon{
        font-size: 7px;
        color: #fff;
    }

    .company-info-location{
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        .company-info-location-span{
            font-size: 15px;
        }
        .company-info-location-text{
            font-size: 15px;
            color: #999999;

        }

    }

    .company-info-intro{
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        .company-info-intro-text{
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            overflow: hidden;
            font-size: 15px;
            color: #333;
            
        }
    }
}