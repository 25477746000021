.wrapper {
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
}

.container {
}

.title {
    background: linear-gradient(90deg, #1c4742, #22c96d);
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    line-height: 26px;
    margin: 0;
    padding: 12px 20px;
}

.body {
    padding: 0 20px 8px;
}

.item {
    padding: 20px 0;
    .box-title {
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        .icon {
            color: #00b14f;
            font-size: 20px;
            height: 20px;
            line-height: 20px;
            margin-right: 8px;
            text-align: center;
            width: 20px;
        }

        .text {
            color: #212f3f;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            line-height: 1.5;
        }
    }
    .content {
        color: #4d5965;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.5;
    }

    &:not(:last-child) {
        border-bottom: 1px solid #e9eaec;
    }
}
