.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    opacity: 0;
    visibility: hidden;
    z-index: 1000;
    transition: all 0.2s linear;

    .container {
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .container.animation {
        transform: translateY(-100px);
        transition: all 0.2s linear;
    }
}

.wrapper.show {
    visibility: visible;
    opacity: 1;

    .container.animation {
        transform: translateY(0);
    }
}

.wrapper.prevent-cancel .container {
    transition: all 0.1s linear;
}

.wrapper.scale .container {
    transform: scale(1.01) !important;
    transition: all 0.1s linear;
}
