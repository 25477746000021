.wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 11px 0;
    gap: 24px;
}

.col-1 {
    width: 10%;
}

.col-2 {
    width: 20%;
}

.col-3 {
    width: 25%;
}

.col-4 {
    width: 45%;
}
