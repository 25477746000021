.wrapper {
    width: 100%;
}

.container {
    width: 100%;
    color: #fff;
}

.header-select {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 19px 12px 2px;
    border-radius: 6px;
    width: 100%;
    border: 1px solid transparent;
    gap: 12px;
    background: rgba(0, 192, 86, 0.16);

    &:hover,
    &.active {
        background-color: #19734e;
        border: 1px solid #33c172;
    }

    .container-select {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 90%;
    }

    .icon {
        height: 24px;
        width: 24px;
        font-size: 20px;
        margin: 0 10px;
        padding: 2px;
    }

    .result {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        padding-left: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .icon-chevron {
        color: #fff;
    }
}
