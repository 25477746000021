.wrapper {
}

.container {
}

.box-search-container,
.featured-company-container {
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
}

.box-search-company {
    margin-bottom: 0;
    padding-top: 32px;

    .box-search-container {
        display: flex;
    }

    .box-search {
        width: 65%;
        .box-search-nav {
            display: flex;
            padding-bottom: 33px;
            flex-wrap: wrap;
        }

        .box-search-nav-item {
            margin-right: 24px;
            position: relative;
        }

        .box-search-nav-link {
            display: block;
            border-color: #333;
            border-bottom: 2px solid transparent;
            color: #333;
            font-size: 14px;
            font-weight: 400;
            padding: 6px 0;
            transition: all 0.15s ease-in-out;

            &.active {
                font-weight: 600;
                border-color: #00b14f;
                color: #00b14f;
            }
        }

        .box-search-title {
            margin-bottom: 37px;
        }

        .box-search-title-text {
            color: #333;
            font-size: 24px;
            font-weight: 700;
            line-height: 30px;
            margin: 0 0 24px;

            .box-search-title-text-highlight {
                color: #00b14f;
            }
        }

        .box-search-title-description {
            color: #333;
            font-size: 16px;
            margin-bottom: 0;
        }

        .box-search-feature {
            display: flex;
            background: #fff 0 0 no-repeat padding-box;
            border: 1px solid #fff;
            border-radius: 25px;
            height: 50px;
            width: 70%;
            overflow: hidden;
            position: relative;
            box-shadow: -1px 1px 6px rgba(0, 0, 0, 0.05);
            transition: all 0.15s ease-in-out;

            &:hover {
                border-color: #00b14f;
            }

            .box-search-feature-icon {
                left: 20px;
                position: absolute;
                top: 50%;
                transform: translateY(-9px);
                width: 24px;
                height: 24px;
            }

            .icon-search {
                color: #a3a3a3;
                font-size: 16px;
            }

            .box-search-feature-input {
                background-color: #fff;
                border-radius: 4px;
                color: #212529;
                font-weight: 400;
                line-height: 1.5;
                padding: 4px 8px 4px 62px;
                transition: all 0.15s ease-in-out;
                border-color: transparent;
                font-size: 14px;
                height: 100%;
                padding-left: 50px;
                padding-right: 130px;
                outline: none;
                width: 100%;
            }

            .box-search-feature-button {
                background: #00b14f;
                border-radius: 100px;
                border: 1px solid #00b14f;
                color: #fff;
                font-size: 15px;
                line-height: 21px;
                position: absolute;
                right: 7px;
                top: 50%;
                transform: translateY(-50%);
                text-align: center;
                padding: 7px 26px;

                transition: all 0.15s ease-in-out;

                &:hover {
                    background-color: #00883d;
                }
            }
        }
    }

    .image-search {
        margin-left: 250px;
        .image-search-company {
            width: 190px !important;
        }
    }
}

.featured-company {
    min-height: calc(100vh - 180px);

    background-color: #f0f0f0;
    padding-bottom: 40px;
    padding-top: 16px;
    .featured-company-container {
    }

    .featured-company-content {
        border-radius: 8px;
        box-shadow: -1px 1px 6px rgba(0, 0, 0, 0.05);
        padding: 24px;
        background-color: #fff;
    }

    .featured-company-title {
        color: #333;
        font-size: 15px;
        margin-bottom: 17px;
    }

    .featured-company-title-highlight {
        color: #00b14f;
        font-weight: 700;
    }

    .featured-company-list {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 12px;
        margin-top: 24px;
    }

    .company {
        overflow: hidden;
        max-width: 100%;
    }
}

@media (min-width: 576px) {
    .box-search-container,
    .featured-company-container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .box-search-container,
    .featured-company-container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .box-search-container,
    .featured-company-container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .box-search-container,
    .featured-company-container {
        max-width: 1140px;
    }
}

@media (max-width: 991px) {
    .featured-company-list {
        grid-template-columns: repeat(1, 1fr) !important;
    }
}

@media (max-width: 575px) {
    .featured-company-list {
        grid-template-columns: repeat(1, 1fr) !important;
    }

    .box-search-container {
        flex-direction: column;
    }
    .box-search-title-text {
        font-size: 18px !important;
    }

    .box-search-title-description {
        font-size: 14px !important;
    }

    .image-search {
        display: none !important;
    }
}

.footer {
    margin: 0 auto;
    margin-bottom: 50px;

    .content-footer {
        align-items: center;
        display: flex;
        gap: 10px;
        justify-content: center;

        .btn {
            align-items: center;
            aspect-ratio: 1/1;
            border: 1px solid #00b14f;
            border-radius: 50%;
            color: #00b14f !important;
            cursor: pointer;
            display: flex;
            justify-content: center;
            width: 28px;
            height: 28px;
        }

        .deactive {
            border: 1px solid #dee0e2;
            color: #dee0e2 !important;
            cursor: unset;
            opacity: 1;
            pointer-events: none;
        }

        .btn:hover {
            background: #00b14f;
            color: #fff;

            .icon {
                color: #fff;
            }
        }

        .icon {
            font-size: 20px;
        }

        .number {
            color: #00b14f;
        }

        .text-page {
            color: #a6acb2;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
        }
    }
}
