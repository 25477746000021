.wrapper {
    width: 100%;
    height: 100%;
    padding: 24px 0;
}

.container {
    position: relative;
}

.container > div > ul {
    bottom: -30px !important;

    li {
        margin: 0 2px !important;
    }

    button::before {
        font-size: 12px;
    }
}

.slide {
    display: flex !important;
    align-items: center;
    flex-direction: column;
    text-align: center;
    min-height: 243px;
}

.img {
    height: 174px;
    margin-bottom: 19px;
}

.content {
    color: #212f3f;
    font-size: 14px;
    font-style: normal;
    line-height: 22px;
    margin-bottom: 16px;
    font-weight: 400;
}

.btn {
    display: flex;
    align-items: center;
    aspect-ratio: 1/1;
    border-radius: 50%;
    color: #00b14f !important;
    background-color: rgba(33, 47, 63, 0.1);
    justify-content: center;
    width: 32px;
    position: absolute;
}

.btn:hover {
    background: #00b14f;
    color: #fff;

    .icon {
        color: #fff;
    }
}

.icon {
    font-size: 20px;
}

.btn-left {
    left: 0;
    bottom: 50%;
}

.btn-right {
    right: 0;
    bottom: 50%;
}
