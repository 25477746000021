.wrapper {
    position: relative;
    padding: 16px 0 0;
    z-index: 2;
}

@media screen and (min-width: 768px) {
    .img {
        height: 150px !important;
    }
}

@media screen and (min-width: 1200px) {
    .img {
        height: 234px !important;
    }
}

.slider > div {
    border-radius: 10px;
}

.content {
    display: block;
}

.img {
    display: inline-block;
    height: 70px;
    object-fit: cover;
    width: 100%;
}

.btn {
    position: absolute;
    align-items: center;
    border-radius: 50%;
    color: #00b14f !important;
    cursor: pointer;
    display: flex;
    justify-content: center;
    width: 40px;
    height: 40px;
    background: #fff;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.08);
}

.btn-left {
    left: 0;
    top: 50%;
    transform: translate(-50%, -50%);
}

.btn-right {
    right: 0;
    top: 50%;
    transform: translate(50%, -50%);
}

.btn:hover {
    background: #00b14f;
    color: #fff;

    .icon {
        color: #fff;
    }
}

.icon {
    font-size: 20px;
}
