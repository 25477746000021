.wrapper {
    background: #f4f5f5;
    padding-top: 24px;
}

@media screen and (min-width: 768px) {
    .container {
        padding-right: 15px;
        padding-left: 15px;
    }
}

.container {
    width: 100%;
    background: #f4f5f5;
    position: relative;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-wrap: wrap;
}

.content {
    // width: calc(100% * 2 / 3);
    width: 100%;
    padding: 0 7.5px;

    .empty{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .img-empty {
            margin-bottom: 30px;
        }

        .text-empty{
            color: #777;
            font-size: 15px;
            font-weight: 400;
            line-height: 20px;
            padding-bottom: 22px;
            text-align: center;

            .text-highlight{
                color: #333;
                font-weight: 600;
                padding-bottom: 10px;
            }
        }

        .btn-action{
            padding-bottom: 16px;

            .btn{
                background: #00b14f;
                border-color: #00b14f;
                border-radius: 5px;
                color: #fff;
                font-size: 15px;
                line-height: 22px;
                padding: 9px 20px;

                &:hover{
                    background: #009e46;
                    border-color: #009e46;
                }
            }
        }
    }

    .box-group {
        background: #fff;
        border-radius: 5px;
        box-shadow: -1px 1px 6px rgba(0, 0, 0, 0.05);
        margin-bottom: 16px;
        padding: 16px;
    }

    .box-group-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        .box-group-header-title {
            font-size: 20px;
            font-weight: 600;
            line-height: 1.4px;
            margin-bottom: 20px;
            color: #333;
        }

        .box-group-header-action {
            // min-width: 200px;
            .input-box-item {
                min-width: 210px;
            }
        }

        .input-box-item {
            border-radius: 6px;
            .header-select {
                padding: 10px 18px;
                align-items: center;
                background: #fff 0 0 no-repeat padding-box;
                border: 1px solid #e0e4e9;
                box-shadow: 0 3px 20px rgba(0, 0, 0, 0.06);
                width: 100%;
                border-radius: 6px;

                &:hover {
                    cursor: pointer;
                    background-color: #f4f5f5;
                }

                .container-select {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    width: 100%;
                    padding: 0 8px;
                }

                .icon-star {
                    height: 24px;
                    width: 24px;
                    color: #6f7882;
                    font-size: 20px;
                    margin: 0 10px;
                    border: 1px solid #6f7882;
                    border-radius: 50%;
                    padding: 2px;
                }

                .result {
                    color: #172530;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 20px;
                    padding-left: 0;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }

            .ul-select {
                overflow-y: auto;
                max-height: 230px !important;

                .item {
                    color: #172530;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 20px;
                    padding: 12px 12px 12px 16px;
                    position: relative;
                    cursor: pointer;
                }

                .item.active {
                    color: #00b14f;
                }

                .text {
                    font-size: 14px;
                    font-weight: 500;
                    color: #172530;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .icon {
                    font-size: 20px;
                    color: #00b14f;
                    position: absolute;
                    right: 12px;
                }
                .item:hover {
                    background: #f4f5f5;
                }

                .item.active .text {
                    color: #00b14f;
                    font-weight: 600;
                }
            }

            .icon-check {
                color: #6f7882;
                box-sizing: content-box !important;
                font-size: 22px;
                // padding-right: 10px;
                padding-left: 10px;
                // padding: 0 ;
                margin-right: 10px;
                position: absolute;
                right: 0;
            }
        }
    }

    .box-group-body {
        .box {
            margin-bottom: 30px;
        }
    }
}

.sidebar {
    // width: calc(100% * 1 / 3);
}

@media (min-width: 1200px) {
    .container {
        width: 1170px;
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .container {
        width: 970px;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .container {
        width: 750px;
    }
}

@media screen and (max-width: 767px) {
    .wrapper {
        min-width: 360px !important;
    }
    .container {
        padding: 0;
    }
}

.footer {
    margin: 0 auto;

    .content-footer {
        align-items: center;
        display: flex;
        gap: 10px;
        justify-content: center;

        .btn {
            align-items: center;
            aspect-ratio: 1/1;
            border: 1px solid #00b14f;
            border-radius: 50%;
            color: #00b14f !important;
            cursor: pointer;
            display: flex;
            justify-content: center;
            width: 28px;
            height: 28px;
        }

        .deactive {
            border: 1px solid #dee0e2;
            color: #dee0e2 !important;
            cursor: unset;
            opacity: 1;
            pointer-events: none;
        }

        .btn:hover {
            background: #00b14f;
            color: #fff;

            .icon {
                color: #fff;
            }
        }

        .icon {
            font-size: 20px;
        }

        .number {
            color: #00b14f;
        }

        .text-page {
            color: #a6acb2;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
        }
    }
}
