.select-dropdown {
    // width: 200px;
    background: #fff;
    border-radius: 6px;
    box-shadow: 0 1px 16px rgba(0, 0, 0, 0.08);
    overflow: hidden;
    z-index: 2;
}

.select-dropdown-search {
    padding: 16px;
}

.input {
    background: #fff;
    border: 1px solid #e9eaec;
    outline: none;
    border-radius: 6px;
    padding: 9px 8px 9px 8px;
    width: calc(100% - 8px);
    color: rgb(51, 51, 51);
    font-size: 14px;
    line-height: 20px;
}

.select-results {
    max-height: 230px;
    overflow: auto;
}

.search-options {
    color: #172530;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    padding: 12px 12px 12px 16px;
    max-height: 230px !important;
    overflow: hidden;
}

.search-options:hover {
    background: #f4f5f5;
    cursor: pointer;
}

.select-option {
    color: #172530;
    font-size: 14px;
    line-height: 20px;
    padding: 12px 12px 12px 16px;
    position: relative;

    .icon-check {
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
        color: #00b14f;
        font-size: 22px;
    }
}

.select-option:hover {
    background: #f4f5f5;
    cursor: pointer;
}

.selected {
    color: #00b14f;
    font-weight: 600;
}
