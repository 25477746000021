.wrapper {
    display: flex;
    justify-content: center;
    padding: 20px;
    align-items: center; 
}

.container {
    width: 900px; 
    max-height: 50vh;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    overflow-y: auto; 
    box-sizing: border-box;
}

.header {
    margin-bottom: 20px;
}

.header-title {
    display: flex;
    justify-content: center;
}

.title {
    font-size: 24px;
    font-weight: bold;
    color: #333;
}

.content {
    margin-top: 20px;
}

.content-info {
    display: flex;
    flex-direction: column;
}

.info {
    margin-bottom: 10px;
}

.info-title {
    font-size: 16px;
    font-weight: bold;
    color: #555;
}

.info-value {
    font-size: 16px;
    color: #333;
}

.Email, .phone, .CV, .time, .cover-letter {
    margin-bottom: 15px;
}

.CV a {
    text-decoration: none;
    color: #007bff;
}

.CV a:hover {
    text-decoration: underline;
}

.cover-letter {
    border-top: 1px solid #ccc;
    padding-top: 15px;
}

.cover-letter .info-title {
    font-size: 18px;
    font-weight: bold;
    color: #222;
    margin-bottom: 10px;
}

.cover-letter .info-value {
    font-size: 16px;
    color: #555;
    white-space: pre-wrap;
    line-height: 1.6;
}

@media (max-width: 1200px) {
    .container {
        width: 600px;
        padding: 15px;
    }

    .title {
        font-size: 22px;
    }

    .info-title, .info-value {
        font-size: 14px;
    }

    .cover-letter .info-title {
        font-size: 16px;
    }

    .cover-letter .info-value {
        font-size: 14px;
    }
}

@media (max-width: 768px) {
    .container {
        width: 400px;
        padding: 10px;
    }

    .title {
        font-size: 20px;
    }

    .info-title, .info-value {
        font-size: 14px;
    }

    .cover-letter .info-title {
        font-size: 14px;
    }

    .cover-letter .info-value {
        font-size: 12px;
    }
}

@media (max-width: 480px) {
    .container {
        width: 300px;
        padding: 10px;
    }

    .title {
        font-size: 18px;
    }

    .info-title, .info-value {
        font-size: 12px;
    }

    .cover-letter .info-title {
        font-size: 12px;
    }

    .cover-letter .info-value {
        font-size: 10px;
    }
}
