.wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 11px 0;
}

.left-col {
    padding: 12px;
}

.right-col {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    gap: 12px;

    .box-title {
        width: calc(100% - 20px);
        height: 20px;

        .title {
            width: 100%;
            height: 20px;
        }
    }

    .box-description {
        width: calc(100% - 40px);
        height: 20px;

        .description {
            width: 100%;
            height: 20px;
        }
    }

    .right-col-row {
        display: flex;
        flex-direction: row;
        gap: 12px;
        width: 100%;

        .box-item {
            width: 30%;
            height: 20px;

            .item {
                width: 100%;
                height: 20px;
            }
        }
    }
}
