.wrapper {
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
}

.container {
}

.title {
    background: linear-gradient(90deg, #1c4742, #22c96d);
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    line-height: 26px;
    margin: 0;
    padding: 12px 20px;
}

.body {
    .content {
        padding: 20px 20px 28px;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-family: 'Inter', sans-serif;
            font-weight: 500;
            line-height: 1.1;
            color: inherit;
        }

        h1,
        h2,
        h3 {
            margin-top: 20px;
            margin-bottom: 10px;
        }

        h4,
        h5,
        h6 {
            margin-top: 10px;
            margin-bottom: 10px;
        }

        h1 {
            font-size: 36px;
        }

        h2 {
            font-size: 30px;
        }

        h3 {
            font-size: 24px;
        }

        h4 {
            font-size: 18px;
        }

        h5 {
            font-size: 14px;
        }

        h6 {
            font-size: 12px;
        }

        p {
            font-size: 14px;
            line-height: 1.5;
            color: #333;
            margin: 0 0 10px;
        }

        color: rgb(51, 51, 51);
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 10px;
    }
}
