.wrapper {
    display: flex;
    align-items: center;
    background-color: #fff;
}

@media screen and (max-width: 1400px) {
    .bg-right-arrow {
        height: 547.77px !important;
        width: 190px !important;
    }
}

@media screen and (max-width: 1200px) {
    .wrapper {
        flex-direction: column-reverse !important;
    }

    .banner {
        background-image: url(../../assets/images/auth_bg_tb.png) !important;
        height: 250px !important;
        width: 100% !important;
    }

    .banner-abs {
        display: none !important;
    }

    .bg-right-arrow {
        display: none !important;
    }
}

@media screen and (max-width: 768px) {
    .banner {
        height: 180px !important    ;
    }

    .auth-form {
        width: 100% !important;
        padding: 0 !important;
    }
}

@media screen and (max-width: 576px) {
    .banner {
        background-image: url(../../assets/images/auth_bg_mb.png) !important;
        height: 160px !important;
        width: 100% !important;
    }
}

@media screen and (min-width: 768px) {
    .alert {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}

.auth-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100vh;
    overflow-y: auto;
    padding: 60px 16px 20px;
    position: relative;

    .auth-inner {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
    }

    .auth-form {
        width: 600px;
        background-color: #fff;
    }

    .header {
        width: 100%;
        padding-bottom: 24px;
        .title {
            color: #00b14f;
            font-size: 20px;
            font-weight: 600;
            line-height: 28px;
            text-align: left;
            margin-bottom: 8px;
            margin-top: 0;
        }

        .desc {
            color: #6c757d;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            margin-bottom: 0;
            text-align: left;
        }
    }

    .body {
        color: #4d5965;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;

        .alert {
            align-items: center;
            background-color: rgba(255, 69, 58, 0.05);
            color: #ff453a;
            font-size: 14px;
            padding: 10px 15px;
            width: 100%;
            display: none;
        }

        .alert.show {
            display: flex !important;
        }

        .form-group {
            margin-bottom: 24px;
        }

        .form-group-password {
            margin-bottom: 20px;
        }

        .input-wrapper {
            border: 1px solid #eee;
            border-radius: 3px;
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            position: relative;
            width: 100%;
            margin-top: 4px;

            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            &:focus-within {
                border-color: #00b14f;
                box-shadow: 0 0 0 0.1rem rgba(0, 177, 79, 0.25);
            }
        }

        .forgot-group-email {
            margin-bottom: 24px;
        }

        .label {
            margin-bottom: 4px !important;
        }

        .input {
            background: #fff 0 0 no-repeat padding-box;
            font-size: 14px;
            padding: 12px 12px;
            flex: 1;
        }

        .icon-eye {
            font-size: 20px;
            margin: 9px 10px;
        }

        .eye-slash {
            color: #00b14f;
            opacity: 0.4;
        }

        .eye {
            color: #00b14f;
        }

        .icon.email,
        .icon.password {
            color: #00b14f;
            font-size: 19px;
            margin: 10px;
        }

        .forgot-password {
            text-align: right;
            .link {
                color: #00b14f;
                font-size: 14px;
                line-height: 1.5;
                font-weight: 500;
                &:hover {
                    color: #028b40;
                    text-decoration: underline;
                }
            }
        }

        .login {
            margin-top: 16px;
            margin-bottom: 16px;
            .btn {
                height: 40px;
                background: #00b14f;
                color: #fff;
                width: 100%;
                border-radius: 4px;
                padding: 6px 12px;
                font-size: 14px;

                &:hover {
                    background: #028b40;
                }

                &:disabled {
                    background: #00b14f;
                    color: #fff;
                    cursor: unset;
                }
            }

            .btn.disabled {
                opacity: 0.5;
                cursor: unset;
            }

            .desc {
                color: #6f7882;
                font-size: 14px;
                line-height: 22px;
                margin-bottom: 16px;
                margin-top: 16px;
                text-align: center;
            }
        }

        .social-login {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 16px;
            margin-bottom: 0;
            .btn {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 4px;
                border-radius: 3px;
                color: #fff;
                height: 40px;
                width: calc(100% / 3);
            }

            .btn-google {
                background: #e73b2f;
                border-color: #e73b2f;
            }

            .btn-facebook {
                background: #1877f2;
                border-color: #0d6efd;
            }

            .btn-linkedin {
                background: #0a66c2;
                border-color: #056497;
            }

            .text {
                font-size: 14px;
            }

            .icon {
                font-size: 14px;
            }
        }

        .disabled .btn {
            opacity: 0.5;
            cursor: unset;
        }

        .policy {
            margin-top: 16px;
            display: flex;
            align-items: flex-start;
            gap: 8px;

            .checkbox {
                display: none !important;
            }

            .label-checkbox::before {
                background: #fff;
                border: 1px solid #646d79;
                border-radius: 5px;
                content: '';
                display: block;
                height: 20px;
                margin-right: 8px;
                width: 20px;
            }

            .checkbox:checked + .label-checkbox::before {
                background: #00b14f;
                border-color: #00b14f;
                color: #fff;
                content: '\2713';
                font-weight: 500;
                text-align: center;
            }

            .link {
                color: #00b14f;
            }
        }

        .options {
            font-size: 14px;
            margin-top: 16px;
            display: flex;
            justify-content: center;

            .link {
                color: #00b14f;
                font-size: 14px;
                line-height: 1.5;
                font-weight: 500;
                &:hover {
                    color: #028b40;
                    text-decoration: underline;
                }
            }
        }

        .support-center {
            margin-top: 16px;
            font-size: 14px;
            text-align: center;
            line-height: 20px;
            .title {
                margin: 0;
                font-weight: 700;
            }

            .link {
                color: #00b14f;
                font-weight: 600;
            }

            &::before {
                border-top: 1px solid #eee;
                content: '';
                display: block;
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
                margin-left: auto;
                margin-right: auto;
                padding-bottom: 16px;
                width: 100%;
            }
        }
    }

    .copy-right {
        color: #00b14f;
        font-size: 14px;
        font-weight: 400;
        line-height: 145%;
        margin-top: 60px;
        text-align: center;
    }
}

.desc {
    .link {
        color: #00b14f;
    }
}

.banner {
    background-image: url(../../assets/images/auth_bg_desktop.png);
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    overflow: hidden;
    position: relative;
    width: 500px;

    .banner-abs {
        left: 35px;
        margin-top: -32px;
        max-width: 300px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    .title {
        color: #fff;
        font-size: 40px;
        font-weight: 700;
        line-height: 60px;
        margin: 24px 0 0 0;
    }

    .desc {
        color: #fff;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        margin-bottom: 16px;
    }

    .bg-right-arrow {
        background-image: url(../../assets/images/auth_arrow.png);
        background-position: 0 0;
        background-repeat: no-repeat;
        background-size: cover;
        height: 608px;
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
        width: 211.08px;
    }
}
