.wrapper {
}

@media screen and (max-width: 1200px) {
    .container {
        min-height: 492px !important;
    }

    .banner {
        height: 162px !important;
    }

    .logo {
        display: flex;
        justify-content: center;

        position: unset !important;
        .box-logo {
            align-items: center;
            display: flex;
            justify-content: center;
            margin-bottom: 24px !important;
            margin-top: -90px;
            position: unset !important;
            left: auto !important;
            position: relative;
            top: auto !important;
        }
    }

    .detail {
        margin-top: 0 !important;
        padding-left: 24px !important;
        padding-top: 0 !important;
        position: unset !important;
        display: flex;
        flex-direction: column;
        align-items: center;

        .box-detail {
            max-width: 100% !important;
            width: 100% !important;
            display: flex !important;
            flex-direction: column;
            align-items: center;
            margin-bottom: 24px !important;
        }
    }
}

.container {
    background: linear-gradient(90deg, #212f3f, #00b14f);
    border-radius: 10px;
    min-height: 358px;
    overflow: hidden;
}

.banner {
    height: 224px;
    overflow: hidden;

    img {
        height: 100%;
        object-fit: cover;
        object-position: center;
        width: 100%;
    }
}

.logo {
    position: relative;
    .box-logo {
        display: flex;
        position: absolute;
        align-items: center;
        justify-content: center;
        background: #fff;
        border: 4.5px solid #fff;
        height: 180px;
        width: 180px;
        border-radius: 90px;
        left: 40px;
        top: -90px;
        overflow: hidden;
    }

    img {
        height: 80%;
        object-fit: contain;
        width: 80%;
    }
}

.detail {
    position: relative;
    display: flex;
    align-items: center;
    column-gap: 32px;
    margin-bottom: 30px;
    margin-top: 30px;
    padding-left: 252px;
    padding-right: 40px;
    color: #fff;

    .box-detail {
        display: block;
        flex: 1 auto;
        max-width: 100%;
        width: 0;

        h1 {
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            color: #fff;
            display: -webkit-box;
            font-size: 20px;
            font-weight: 600;
            line-height: 28px;
            margin-bottom: 16px;
            margin-top: 0;
            max-width: 100%;
            overflow: hidden;
            width: fit-content;
        }

        .sub-detail {
            align-items: center;
            column-gap: 20px;
            display: flex;
            flex-wrap: wrap;
            max-width: 100%;
            row-gap: 16px;
        }

        .label {
            box-sizing: border-box;
            border-radius: 3px;
            font-size: 12px;
            font-weight: 600;
            line-height: 18px;
            margin-right: auto;
            min-height: 25px;
            padding: 3px 20px 3px 6px;
            position: relative;
            text-transform: uppercase;
            max-width: 100%;
            margin-bottom: 5px;
        }

        .label::before {
            background: #fff;
            border-top-right-radius: 5px;
            content: '';
            height: 21px;
            position: absolute;
            right: -10px;
            top: 50%;
            transform: translateY(-50%) rotate(-45deg);
            width: 21px;
        }

        .vnr500 {
            background: linear-gradient(90deg, #fd7222, #edab28);
        }

        .fast500 {
            background: #243a76;
        }

        .profit500 {
            background: #dc2f2f;
        }

        .v1000 {
            background: #e48924;
        }

        .detail-box {
            white-space: nowrap;
            display: flex;
            align-items: center;

            .icon-box {
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
                font-size: 16px;
                margin-right: 16px;
            }

            .text {
                color: #fff;
                display: block;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }

    .box-follow {
        button {
            display: flex;
            align-items: center;
            justify-content: center;
            background: #fff;
            border-radius: 8px;
            color: #00b14f;
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            height: 48px;
            width: 194px;
            padding: 6px 12px;
            transition: all 0.3s ease;

            &:hover {
                background: #e5f7ed;
            }
        }

        .icon {
            margin-right: 8px;
        }
    }
}
