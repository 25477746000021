.container {
    align-items: flex-start;
    align-self: stretch;
    background: #fff;
    border: 1px solid #f2f4f5;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 12px;
}

.info {
    align-items: flex-start;
    display: flex;
    gap: 12px;
    width: 100%;
    .logo-container {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #e9eaec;
        border-radius: 8px;
        height: 78px;
        padding: 2px;
        width: 78px;
    }

    .logo {
        border-radius: 5px;
        flex-shrink: 0;
        object-fit: contain;
        display: block;
        max-width: 100%;
        height: auto;
    }

    .detail {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: 8px;
        width: calc(100% - 90px);
    }

    .title {
        color: #212f3f;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        height: 20px;
        line-height: 22px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
    }

    .company-name {
        color: #6f7882;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        height: 16px;
        line-height: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
    }
}

.tags {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
    .list {
        align-items: center;
        display: flex;
        gap: 6px;
        // max-width: 80%;
        flex: 1;
        flex-wrap: wrap;
    }

    .tag {
        align-items: center;
        background: #f4f5f5;
        border-radius: 4px;
        color: #212f3f;
        display: flex;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        justify-content: center;
        letter-spacing: 0.12px;
        line-height: 16px;
        padding: 4px 10px;
    }

    .like {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 20px;
        width: 20px;

        .icon {
            color: #bcc1c5;
            font-size: 18px;
        }
    }
}
