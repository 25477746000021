.wrapper {
}

.item-title {
    margin-bottom: 16px;
    display: flex;

    .title {
        font-size: 16px;
        line-height: 19x;
        font-weight: 600;
        color: #212f3f;
    }
}

.box-content-group {
    margin-bottom: 14px;

    .label {
        display: inline-block;
        font-weight: 600;
        color: #5e6368;
        margin-bottom: 8px;
        font-size: 14px;
        line-height: 1.5;
    }

    .label-secondary {
        color: #5e6368;
        font-weight: 500;
    }

    .label-salary {
        position: relative;
        width: 100%;
        .label-salary-box {
            font-size: 14px;
            line-height: 1.5;
            color: #303235 !important;
            font-weight: 500;
            position: absolute;
            right: 0;
            top: 0;
            display: flex;
            align-items: center;
            gap: 6px;
        }

        .checkbox {
            cursor: pointer;
        }

        .label-checkbox {
            cursor: pointer;
        }
    }

    .required {
        color: #c52e20;
    }

    .icon-logo-text {
        width: 60px;
        transform: translate(2px, 2px);
    }

    .select-box {
        .select-button {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 8px;
            min-width: 100px;
            font-size: 14px;
            white-space: nowrap;
            border-radius: 5px;
            color: #fff;
            background-color: #00b14f;
            border-color: #00b14f;
            border: 1px solid transparent;
            padding: 6px 11px;
            transition: all 0.15s ease-in-out;
            font-family: 'Inter', sans-serif;
            line-height: 1.5;
            &:hover {
                color: #fff;
                background-color: #008b3e;
                border-color: #007e38;
            }
            .icon-plus {
                font-size: 14px;
            }
        }
    }
}

.input-box {
    .input-box-item {
        border: 1px solid #e8edf2;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        border-radius: 4px;
        position: relative;
        &:focus-within {
            border-color: #00b14f;
            box-shadow: 0 0 0 0.1rem rgba(0, 177, 79, 0.25);
        }
    }

    .input-box-right {
        position: absolute;
        right: 10px;
        top: 10px;
        color: #868d94;
        display: flex;
        align-items: center;
        gap: 6px;
    }

    .input-box-right-icon {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.5;
        display: flex;
        align-items: center;
        gap: 6px;
    }

    .icon-circle-xmark {
        font-size: 16px;
        cursor: pointer;

        &:hover {
            color: #c52e20;
        }
    }

    .input {
        background-color: #fff;
        border: 1px solid #f1f3f4;
        padding-right: 105px;
        color: #495057;
        text-overflow: ellipsis !important;
        height: 40px;
        padding: 8.5px 14px;
        font-size: 14px;
        line-height: 1.5;
        border-radius: 4px;
        width: 100%;
        font-weight: 400;
        transition: all 0.15s ease-in-out;
    }

    .icon-warning {
        font-size: 18px;
        color: #c52e20;
    }

    .input-box-feedback {
        font-size: 100%;
        min-height: 22px;
        width: 100%;
        margin-top: 3.5px;
        color: #dc3545;
    }

    .feedback-text {
        font-size: 14px;
        line-height: 1.5;
        color: #dc3545;
        font-weight: 500;
    }
}

.box-content-job-type {
    width: calc(50% - 10px);
    .job-type {
        border: 1px solid #00b14f;
        background-color: #f5fff9;
        border-radius: 4px;
        position: relative;
    }

    .job-type-icon {
        position: absolute;
        border-radius: 4px;
        right: -1px;
        top: -1px;
    }

    .icon-check-mark {
        margin-left: 2px;
        margin-bottom: 3px;
        width: 20px;
        height: 20px;
    }

    .job-type-title {
        font-size: 12px;
        width: fit-content;
        padding: 0 10px;
        height: 20px;
        background: #d7dee4;
        margin-left: 16px;
        margin-top: -1px;
        position: relative;
        font-weight: 600;
        line-height: 1.5;
        clip-path: polygon(
            0 0,
            100% 0,
            92.452% 81.142%,
            92.452% 81.142%,
            92.09% 84.437%,
            91.645% 87.477%,
            91.125% 90.238%,
            90.536% 92.7%,
            89.886% 94.842%,
            89.182% 96.642%,
            88.431% 98.079%,
            87.642% 99.132%,
            86.82% 99.779%,
            85.974% 100%,
            14.026% 100%,
            14.026% 100%,
            13.18% 99.779%,
            12.358% 99.132%,
            11.569% 98.079%,
            10.818% 96.642%,
            10.114% 94.842%,
            9.464% 92.7%,
            8.875% 90.238%,
            8.355% 87.477%,
            7.91% 84.437%,
            7.548% 81.142%,
            0 0
        );

        &::before {
            content: '';
            border-bottom: 20px solid transparent;
            position: absolute;
            top: 0;
            left: -10px;
            border-right: 10px solid #d7dee4;
        }

        &::after {
            content: '';
            border-top: 20px solid transparent;
            position: absolute;
            bottom: 0;
            border-left: 10px solid #d7dee4;
            right: -10px;
        }
    }

    .job-top {
        width: 115px;
        background: #00b14f;
        color: #fff;
    }

    .job-type-text {
        margin: 16px 0 0 16px;
        .label {
            width: 100%;
            margin-bottom: 7px;
            display: flex;

            .text {
                color: #00b14f !important;
                font-size: 16px;
                font-weight: 600;
            }

            .highlight {
                color: #343a40 !important;
            }
        }
    }

    .icon-magic {
        font-size: 14px;
    }

    .job-type-description {
        background: #f5f8fa;
        padding: 8px;
        .description {
            font-size: 12px;
            margin-left: 8px;
        }
    }

    .service {
        color: #00b14f !important;
        font-size: 12px;
        font-weight: 600;
        cursor: pointer;
    }
}

.box-content-flex {
    display: flex;
    gap: 20px;
    flex-direction: row;

    .box-content-group {
        max-width: calc(50% - 10px);
        width: 50%;
    }
}

.box-content-group-time {
    .input-box-time {
        width: 15%;
        position: relative;
    }

    .icon-clock-end {
        position: absolute;
        right: 10px;
        top: 10px;
        color: #d2d2d2 !important;
        cursor: pointer;
        z-index: 1;

        &:hover {
            color: #797979 !important ;
        }
    }

    .input {
        display: block;
        font-family: 'Inter', sans-serif;
        width: 100%;
        height: 34px;
        padding: 6px 12px;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #fff;
        border-radius: 0.25rem;
        transition: all 0.15s ease-in-out;
        padding-left: 36px !important;
    }

    input[type='time']::-webkit-calendar-picker-indicator {
        color: transparent;
        background: none;
        z-index: 1;
        position: absolute;
        width: 100%;
        height: 100%;
    }

    input[type='time']::-webkit-input-placeholder {
        color: #868d94;
    }

    .input-box {
        margin-bottom: 8px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 16px;
    }

    .input-time {
        padding: 5px 12px;
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        height: 31.5px;
    }

    .icon-clock {
        font-size: 14px;
        color: #00b14f;
        width: 14px;
        height: 14px;
        min-width: 14px;
    }

    .input-box-item {
        width: 140px;
    }

    .icon-minus {
        margin: 0 -12px;
        color: #303235;
        opacity: 0.5;
    }
    .button-remove {
        margin-left: -10px;

        &:hover {
            color: #c52e20;
        }
    }

    .button-add {
        color: #00b14f !important;
        font-size: 14px;
        line-height: 21px;
        display: flex;
        align-items: center;
        gap: 6px;
        padding: 6px 0;
    }

    .input-box-description {
        margin-top: 4px;

        .input-description {
            display: block;
            width: 100%;
            height: 42px;
            padding: 5px 10px;
            font-family: 'Inter', sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.5;
            color: #495057;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #e8edf2;
            border-radius: 0.25rem;
            transition: all 0.15s ease-in-out;
            min-height: 54px;
            overflow: hidden;
            resize: none;

            &:focus {
                border-color: #00b14f;
                outline: none;
                color: #495057;
                background-color: #fff;
            }
        }
    }
}

.box-input-gender {
    .header-select {
        padding: 6.25px 19px 6.25px 10px !important;
    }

    .input-box-gender {
        width: calc(100% / 3 - 20px);
    }
}

.header-select {
    padding: 7px 19px 7px 10px;
    align-items: center;
    background: #fff 0 0 no-repeat padding-box;
    border: none;
    border-radius: 6px !important;
    box-shadow: 0 3px 20px rgba(0, 0, 0, 0.06);
    width: 100%;

    .container-select {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
    }

    .result {
        color: #172530;
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        padding-left: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.ul-select {
    overflow-y: auto;
    max-height: 230px !important;

    .item {
        color: #172530;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        padding: 12px 12px 12px 16px;
        position: relative;
        cursor: pointer;
    }

    .item.active {
        // color: #00b14f;
        color: #fff !important;
        background: #00b14f !important;
    }

    .text {
        font-size: 14px;
        font-weight: 500;
        color: #172530;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .icon {
        font-size: 20px;
        color: #00b14f;
        position: absolute;
        right: 12px;
    }
    .item:hover {
        background: #f4f5f5;
    }

    .item.active .text {
        // color: #00b14f;
        color: #fff !important  ;
        font-weight: 600;
    }

    .icon-check {
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 22px;
        color: #fff !important;
    }
}

.icon-care {
    font-size: 16px;
    margin-top: 4px;
}

.input-salary {
    display: flex;
    flex-direction: row;
    align-items: center;

    .salary-type {
        width: 80px;
    }

    .input {
        height: 35px;
        width: calc((100% - 80px - 10px) / 2);
        transition: all 0.15s ease-in-out;

        &:focus {
            border-color: #00b14f;
            outline: none;
        }

        &:disabled {
            color: #52759b !important;
            border: 1px solid #d7dee4 !important;
            background: #e8edf2 !important;
        }
    }

    .header-select {
        padding: 7px 19px 7px 10px;
        align-items: center;
        background: #fff 0 0 no-repeat padding-box;
        border: none;
        border-radius: 6px !important;
        box-shadow: 0 3px 20px rgba(0, 0, 0, 0.06);
        width: 100%;

        .container-select {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
        }

        .result {
            color: #172530;
            font-size: 14px;
            font-weight: 500;
            line-height: 21px;
            padding-left: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .ul-select {
        overflow-y: auto;
        max-height: 230px !important;

        .item {
            color: #172530;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            padding: 12px 12px 12px 16px;
            position: relative;
            cursor: pointer;
        }

        .item.active {
            // color: #00b14f;
            color: #fff !important;
            background: #00b14f !important;
        }

        .text {
            font-size: 14px;
            font-weight: 500;
            color: #172530;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .icon {
            font-size: 20px;
            color: #00b14f;
            position: absolute;
            right: 12px;
        }
        .item:hover {
            background: #f4f5f5;
        }

        .item.active .text {
            // color: #00b14f;
            color: #fff !important  ;
            font-weight: 600;
        }

        .icon-check {
            position: absolute;
            right: 16px;
            top: 50%;
            transform: translateY(-50%);
            font-size: 22px;
            color: #fff !important;
        }
    }

    .icon-care {
        font-size: 16px;
        margin-top: 4px;
    }
}
