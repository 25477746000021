.wrapper {
    position: relative;
    background: url(../../../../../assets/images/bg-search-left-opacity.png), url(../../../../../assets/images/bg-search-right.png),
        linear-gradient(90deg, #212f3f, #00b14f);
    background-position: 0 0, 100% 0, 50%;
    background-repeat: no-repeat;
    background-size: auto 340px, auto 340px, 100% 100%;
    padding: 16px 0;
}

.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.group-box {
    display: flex;
    flex-direction: column;
    gap: 12px;
    justify-content: space-between;
    margin-bottom: 12px;
}

.box-search {
    margin-bottom: 16px;
    position: relative;
    z-index: 4;
}

.btn-filter {
    padding: 6px 12px;
    margin-bottom: 0;
    text-align: center;
    border: 1px solid transparent;
    border-radius: 6px;
    background: #00b14f;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin-top: 12px;
    width: 100%;
    height: 48px;

    &:hover {
        background: #00a045;
    }
}

@media screen and (max-width: 767px) {
    .wrapper {
        padding: 40px 0 24px;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .wrapper {
        padding: 40px 0 24px;
    }

    .container {
        width: 750px;
    }

    .search {
        background: #fff;
        border-radius: 6px;
        flex-direction: row;
        gap: 0;
        margin-bottom: 12px;
    }

    .box-filter {
        flex-direction: row;
        gap: 12px;
        margin-bottom: 12px;
    }
}

@media screen and (min-width: 992px) {
    .container {
        width: 970px;
    }
    .group-box {
        flex-direction: row;
        gap: 12px;
        margin-bottom: 12px;
    }
}
@media screen and (min-width: 1200px) {
    .wrapper {
        height: 80px;
        padding: 16px 0 0 0;
    }

    .container {
        width: 1170px;
    }

    .box-search {
        display: flex;
        gap: 18px;
        margin-bottom: 0px;
    }

    .group-box {
        gap: 18px;
        margin-bottom: 0;
    }

    .box-filter {
        margin-bottom: 0;
    }

    .btn-filter {
        margin-top: 0;
        min-width: 120px;
    }
}
