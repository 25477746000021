@font-face {
    font-family: 'Inter';
    src: local('Inter-Black'), url(./font/Inter-Black.ttf) format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: local('Inter-Bold'), url(./font/Inter-Bold.ttf) format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: local('Inter-SemiBold'), url(./font/Inter-SemiBold.ttf) format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: local('Inter-Medium'), url(./font/Inter-Medium.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: local('Inter-Regular'), url(./font/Inter-Regular.ttf) format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: local('Inter-Light'), url(./font/Inter-Light.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: local('Inter-ExtraLight'), url(./font/Inter-ExtraLight.ttf) format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: local('Inter-Thin'), url(./font/Inter-Thin.ttf) format('truetype');
    font-weight: 100;
    font-style: normal;
}

* {
    margin: 0;
    padding: 0;
    list-style: none;
    box-sizing: border-box;
}

html {
    font-family: 'Inter', sans-serif;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
}

body {
    background-color: #fbfbfb !important;
    color: #4a4a4a;
    margin: 0;
    padding: 0;
    font-family: 'Inter', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
}

body::-webkit-scrollbar {
    width: 6px;
}

body::-webkit-scrollbar-thumb {
    background: #c8c8c9;
    border-radius: 8px;
}

a {
    text-decoration: none;
    color: black;
}

button {
    border: none;
    outline: none;
    cursor: pointer;
    background-color: transparent;
}

input {
    outline: none;
    border: none;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.ck-balloon-panel_position_border-side_right {
    display: none !important;
}

/*
    Message
*/
.cs-message__content {
    background-color: rgb(240, 240, 240) !important;
}

.cs-message-separator {
    color: rgb(101, 104, 108) !important;
    font-weight: 500;
    font-size: 12px;
    padding: 10px 0;
}

.cs-message--outgoing .cs-message__content {
    background-color: rgb(0, 132, 255) !important;
}

.cs-message--outgoing .cs-message__content {
    color: white !important;
}

.cs-message__content:has(.cs-message__file) {
    background-color: rgb(240, 240, 240) !important;
    font-weight: 500;
}

.cs-message__content:has(.cs-message__image) {
    background-color: rgb(255, 255, 255) !important;
    padding: 0 !important;
    font-weight: 500;
}

.cs-message-separator::after,
.cs-message-separator::before {
    background-color: rgb(255, 255, 255) !important;
}

.cs-conversation--active {
    background-color: rgb(235, 245, 255) !important;
    border-radius: 10px;
}

.cs-conversation:hover {
    background-color: rgb(235, 245, 255) !important;
    border-radius: 10px;
}

.cs-message-input__content-editor-wrapper,
.cs-message-input__content-editor,
.cs-sidebar .cs-search,
.cs-sidebar .cs-search__input {
    background-color: rgb(240, 242, 245) !important;
}
