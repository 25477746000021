.wrapper {
    width: 100%;
    height: 100%;
    padding: 24px 0;
}

@media screen and (min-width: 768px) {
    .content {
        padding: 0 10px;
    }

    .img {
        height: 604px !important;
    }
}
@media screen and (min-width: 1200px) {
    .img {
        height: 660px !important;
    }
}

.container > div > ul {
    bottom: 20px !important;

    button::before {
        font-size: 12px;
    }
}

.content {
    display: block;
}

.img {
    display: inline-block;
    height: 577px;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
}
