.wrapper {
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding-bottom: 32px;
    padding-top: 32px;
}

@media screen and (max-width: 450px) {
    .col:last-child {
        display: none !important;
    }
}

@media screen and (max-width: 768px) {
    .title-2 {
        font-size: 18px !important;
        font-weight: 700 !important;
        line-height: 28px !important;
        margin: 0 0 14px !important;
    }

    .list-column {
        flex-direction: column !important;
    }
}

@media (min-width: 1100px) {
    .list-column {
        display: flex;
        justify-content: space-between;

        .col:first-child {
            width: 542px;
        }

        .col:last-child {
            width: 571px;
        }
    }
}

@media (min-width: 768px) {
    .container {
        width: 750px;
    }

    .title-2 {
        font-size: 20px !important;
        font-weight: 700 !important;
        line-height: 29px !important;
    }
}

@media (min-width: 992px) {
    .container {
        width: 970px;
    }

    .title-2 {
        font-size: 22px !important;
        font-weight: 700 !important;
        line-height: 32px !important;
    }
}
@media (min-width: 1200px) {
    .container {
        width: 1170px;
    }
}
.container {
    box-sizing: border-box;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.list-column {
    display: flex;
    gap: 27px;
    justify-content: space-between;

    .title-1 {
        color: #fff;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        margin: 0 0 10px;
    }
    .title-2 {
        color: #fff;
        font-size: 26px;
        font-weight: 700;
        line-height: 38px;
        margin: 0 0 16px;
        display: block;

        @keyframes fadeInOut {
            0% {
                opacity: 0;
            }
            20% {
                opacity: 1;
            }
            80% {
                opacity: 1;
            }
            100% {
                opacity: 0;
            }
        }

        .text {
            color: #00b14f;
            opacity: 0;
            animation: fadeInOut 2000ms infinite ease-out;
            display: none;
        }

        .text.active {
            opacity: 1;
            display: inline-block;
        }
    }
}

.col {
    width: 100%;
}

.show-video {
    background: 0 0;
    border: 1px solid #0b8e50;
    border-radius: 12px;
    overflow: hidden;
    padding: 0;
    margin-top: 8px;

    .img {
        display: block;
        max-width: 100%;
        height: auto;
    }
}

.box-demand-job {
    background: rgba(33, 47, 63, 0.3);
    border: 1px solid transparent;
    border-radius: 12px;
    overflow: hidden;
    padding: 16px 24px;
    position: relative;
    transition: 0.3s;

    &:hover {
        border: 1px solid #11d769;
        box-shadow: 0 0 14px rgba(1, 226, 99, 0.2);

        .more-info {
            bottom: -16px !important;
        }
    }
}

.job-market {
    margin-bottom: 16px;
    span {
        color: #fff;
        font-weight: 600;
        letter-spacing: 0.175px;
        line-height: 22px;
    }
}

.job-market__header {
    align-items: center;
    color: #fff;
    display: flex;
    justify-content: space-between;
    margin-bottom: 22px;

    span {
        gap: 8px;
        font-size: 14px !important;
    }

    img {
        height: 24px;
        transform: translateY(8px);
        margin-right: 2px;
    }

    .datetime {
        color: #11d769;
    }
}

.job-market__content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 0 8px 18px;

    .job-hiring {
        display: flex;
        align-items: center;
        gap: 8px;
        border-image-slice: 1;
        border-image-source: linear-gradient(90deg, rgba(17, 215, 105, 0), #11d769 51.04%, rgba(17, 215, 105, 0));
        border-right: 1px solid;
        position: relative;
    }

    .job-hiring:last-child {
        justify-content: end;
    }

    span {
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 14px !important;
    }

    .quantity {
        color: #11d769;
    }

    .icon {
        background: rgba(17, 215, 105, 0.1);
        border-radius: 15px;
        color: #11d769;
        padding: 6px;
        size: 6px;
        box-sizing: content-box;
    }
}

.job-market::after {
    background: linear-gradient(90deg, rgba(17, 215, 105, 0), #11d769 50%, rgba(17, 215, 105, 0));
    content: '';
    display: block;
    height: 2px;
    opacity: 0.6;
}

.header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 14px;

    span {
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
    }
    .header__title {
        align-items: center;
        display: flex;
        gap: 8px;
    }

    img {
        width: 24px;
    }

    .box-select {
        position: relative;

        .ul-select {
            // max-height: 200px;
            // overflow-y: auto;
            background: #fff;
            z-index: 2;

            .item {
                color: #172530 !important;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                padding: 12px 12px 12px 16px;
                cursor: pointer;

                .text {
                    font-size: 14px;
                    font-weight: 500;
                    color: #172530;
                }

                .icon {
                    font-size: 20px;
                    color: #00b14f;
                    position: absolute;
                    right: 12px;
                }
            }

            .item:hover {
                background: #f4f5f5;
            }

            .item.active .text {
                color: #00b14f;
                font-weight: 600;
            }
        }
    }

    .selected {
        align-items: center;
        background: linear-gradient(178.55deg, rgba(0, 177, 79, 0) 10.22%, rgba(0, 146, 65, 0.22) 98.77%);
        border: 1px solid #11d769;
        border-radius: 6px;
        display: flex !important;
        height: 100% !important;
        padding: 8px 12px;
        // padding-right: 30px;
        width: 132px;
    }

    .text {
        color: #11d769;
    }

    .icon-chevron {
        box-sizing: content-box !important;
        font-size: 30px;
        color: #11d769;
        padding-left: 8px;
        // padding-right: 3px;
        // padding-left: 3px;
        margin-right: 8px;
    }

    .icon-chevron.rotate {
        transform: rotate(180deg);
    }

    .list-select {
        display: block;
        position: absolute;
        top: 36px;
        left: 0px;
        //     height: 36px!important;
        // width: 132px!important;
        margin-top: 4px;

        .item {
            background-color: #fff;
            color: #00b14f;
            background: #f4f5f5;
        }

        .select-container {
            background: #fff;
            border: none;
            border-radius: 6px !important;
            box-shadow: 0 1px 16px rgba(0, 0, 0, 0.06);
            overflow: hidden;
            z-index: 2;

            width: 132px;

            .selection {
                display: block;
            }
        }
    }
}

.box-chart {
    position: relative;

    .more-info {
        background: #1b6748;
        border: 1px solid #00b14f;
        border-bottom: none;
        border-radius: 10px 0 0 0;
        border-right: none;
        padding: 5px 9px 5px 11px;
        text-align: center;
        transition: all 0.3s ease-out;
        position: absolute;
        display: flex;
        align-items: center;
        bottom: -48px;
        right: -24px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;

        .text {
            color: #11d769;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
        }
        .icon {
            color: #11d769;
            font-size: 20px;
            font-weight: 900;
            line-height: 20px;
            margin-left: 4px;
        }
    }

    .more-info:hover .icon,
    .more-info:hover .text {
        color: #fff;
    }

    .more-info:hover {
        background: #00b14f;
    }
}
