.wrapper {
    width: 100%;
}

@media screen and (max-width: 768px) {
    .container {
        padding: 20px 16px !important;
    }

    .label {
        font-size: 16px !important;
        font-weight: 600 !important;
        line-height: 24px !important;
        margin: 0 0 12px !important;
    }
}

.container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    background: #fff;
    border-radius: 8px;
    padding: 20px;
}

.label {
    color: #212f3f;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.2px;
    line-height: 28px;
    margin-bottom: 12px;
}

.tags {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 8px;

    .tag {
        background: #f4f5f5;
        border-radius: 3px;
        color: #212f3f;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        padding: 4px;

        &:hover {
            color: #00b14f;
        }
    }
}
