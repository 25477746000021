.wrapper {
    border-radius: 5px;
    padding: 15px;
    display: flex;
}

.title {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
}

.value {
    font-size: 14px;
    line-height: 1.5;
    font-weight: 500;
    margin-bottom: 0.5rem;
}

.icon-container {
    margin-left: auto;
    width: 40px;
    font-size: 18px;
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: center;
    border-color: transparent;
    margin-right: 5px;
    border-radius: 50rem;
}
