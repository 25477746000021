.wrapper {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;
    margin-bottom: 16px;
}

.container {
    width: calc(100% - 15px);
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.district {
    max-width: calc(100% / 3);
    padding-left: 15px;
    padding-right: 15px;
}

.input {
    flex: 1;

    .input-text {
        display: block;
        width: 100%;
        height: 100%;
        padding: 7px 11px;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.5;
        background-color: #fff;
        border: 1px solid #e8edf2;
        border-radius: 4px;
        transition: border-color all 0.15s ease-in-out;
        color: #000;
        text-overflow: ellipsis !important;

        &:disabled {
            background: #e8edf2 !important;
            opacity: 1;
        }
    }
}

.remove {
    position: absolute;
    right: 0;
    top: 6px;
    cursor: pointer;
    .icon {
        font-size: 14px;
        color: #00b14f;
    }
}
