.btn {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    height: 32px;
    // width: 36px;
    font-size: 20px;
    z-index: 1;
    background-color: #303235;
    display: none;
    color: #fff;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
    border-radius: 40px;
    padding: 2px 20px;
    gap: 5px;
    &:hover {
        background-color: #088641;
    }

    .icon {
        font-size: 13px;
    }
    .text {
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 21px;
        letter-spacing: 0.163px;
    }
}

.visible {
    display: flex !important;
}
