.wrapper {
    width: 100%;
}

.container {
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    width: 100%;
}

.title {
    color: #212f3f;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 16px;
    margin-top: 0;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .item {
        align-items: center;
        display: flex;
        gap: 16px;
    }

    .icon-container {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #00b14f;
        border-radius: 30px;
        gap: 10px;
        height: 40px;
        padding: 10px;
        width: 40px;
    }

    .icon {
        width: 24px;
        height: 24px;
    }

    .info {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    .label {
        color: #4d5965;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
    }

    .value {
        color: #212f3f;
        font-size: 14px;
        font-weight: 600;
        line-height: 22px;
    }
}
