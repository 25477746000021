.wrapper {
    width: 100%;
    background: #fff;
    border: 1px solid #00b14f;
    border-radius: 8px;
    padding: 16px 20px 0 20px;
}

@media screen and (max-width: 768px) {
    .wrapper {
        border: none !important;
        margin-top: -20px !important;
    }
}

.container {
    width: 100%;
}

.title {
    color: #212f3f;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    margin: 0 0 16px;
    padding: 0 5px;
    display: flex;
    align-items: baseline;

    .icon {
        color: #00b14f;
        font-size: 17px;
        line-height: 24px;
        margin-right: 9px;
        text-align: center;
    }
}

.description {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 16px;
    color: #212f3f;
    font-weight: 400;
}

.common-signal {
    margin-bottom: 16px;

    .common-signal-title {
        color: #00b14f;
        display: grid;
        font-size: 14px;
        font-weight: 600;
        line-height: 22px;
        margin: 0;
    }

    .common-signal-content {
        .ul {
            display: grid;
            gap: 12px;
            margin: 16px auto;
            padding: 0;
            width: 85%;

            .li {
                list-style: disc;
                color: #212f3f;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
                // position: relative;

                a {
                    color: #00b14f;
                }
            }
        }
    }

    .common-signal-button {
        text-align: center;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 16px;
        background: #fff;
        border: 1px solid #99e0b9;
        border-radius: 6px;
        color: #00b14f;
        font-weight: 600;
        padding: 8px 16px;
        width: 100%;

        &:hover {
            background: rgba(0, 177, 79, 0.102);
            color: #00b14f;
        }
    }

    .common-signal-note {
        font-size: 14px;
        line-height: 22px;
        color: #212f3f;

        a {
            color: #00b14f;
        }
    }
}
