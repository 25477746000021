.wrapper {
    background-color: #fff;
}

.wrapper * {
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    color: #303235;
}

.container {
    display: flex;
    flex-direction: column;
    padding: 18px;
    word-wrap: break-word;
    border-radius: 4px;
}

.nav-tabs {
    display: flex;
    flex-wrap: wrap;
}

.nav-tab {
    width: 100%;
    max-width: 50%;
    cursor: pointer;
    .nav-tab-item {
        display: flex;
        flex-wrap: wrap;
        border: 1px solid transparent;
        border-bottom: 2px solid transparent;
        color: #495057;
        background-color: #fff;
        padding: 7px 14px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        .nav-tab-item-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 15px 0 1px;
        }

        .nav-tab-item-text {
            position: relative;
            flex: 1;
        }

        .nav-tab-item-title {
            font-size: 14px;
            font-weight: 500;
            line-height: 1.5;
            color: #303235;
        }

        .nav-tab-item-description {
            font-size: 12px;
            font-weight: 400;
            line-height: 1.5;
            color: #6c757d;
        }

        .nav-tab-item-tick {
            visibility: hidden;
            padding: 0 1px 0 15px;
        }

        .icon {
            width: 34px;
            height: 34px;
            font-size: 14px;
            padding: 10px;
            line-height: 1;
            background: #f5f8fa;
            padding: 10px;
            border-radius: 50%;
        }
    }

    &.active .nav-tab-item {
        border-color: #dee2e6 #dee2e6 #fff;
        color: #495057;
        background-color: #fff;
        border-bottom: 2px solid #00b14f !important;
    }

    &.active {
        .icon,
        .nav-tab-item-title,
        .nav-tab-item-tick {
            color: #00b14f !important;
            fill: #00b14f !important;
        }

        .nav-tab-item-tick {
            visibility: visible !important;
        }

        .icon-check {
            fill: #fff !important;
            color: #fff !important;
            background-color: #00b14f;
            border-radius: 50%;
            font-size: 20px;
            padding: 4px;
        }
    }
}

.content-tabs {
}

.content-tab {
    display: none;
    width: 100%;
    &.active {
        display: block;
    }
}
