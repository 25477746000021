.wrapper {
    background: #fff;
    border: 1px solid #f4f4f4;
    border-radius: 5px;
    cursor: pointer;
    margin: auto auto 16px;
    padding: 12px;
    background-color: #f2fbf6;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:hover {
        background-color: #f2fbf6;
        border: 1px solid #00b14f;
    }
}

.wrapper-1 {
    background-color: #fff;
    &:hover {
        background-color: #f2fbf6;
        border: 1px solid transparent;
    }
}

.wrapper-2 {
    background-color: #f2fbf6 !important;
    border: 1px solid #acf2cb !important;
    box-shadow: 0 8px 16px 0 rgba(1, 18, 34, 0.04);
    &:hover {
        background-color: #f2fbf6 !important;
        border: 1px solid #00b14f !important;
    }
}

.container {
    display: flex;
    gap: 16px;
    position: relative;
}

.logo-container {
    display: flex;
    background-image: linear-gradient(201.63deg, #003e86 14.36%, #02d15e 95.71%);
    background-repeat: no-repeat;
    background-size: 100%;
    padding: 2px;
    position: relative;
    align-items: center;
    aspect-ratio: 1/1;
    background-color: #fff;
    border: 1px solid #e9eaec;
    border-radius: 8px;
    height: 120px;
    width: 120px;
    margin: 0 auto;
    object-fit: contain;

    .logo-link {
        background: #fff;
        border-radius: 6px;
        padding: 8px;
        display: block;
        height: 100%;
        overflow: hidden;
        width: 100%;
    }
    .logo {
        border-radius: 5px;
        height: 100%;
        object-fit: contain;
        width: 100%;
        background: #fff;
        vertical-align: middle;
    }

    .flash {
        left: -11px;
        position: absolute;
        top: -21px;
        .icon {
            height: 33.76px;
            width: 24px;
            object-fit: contain;
            border-radius: 5px;
        }
    }
}

.tooltip {
    background-color: #000;
    border-radius: 4px;
    color: #fff !important;
    font-size: 14px !important;
    line-height: 20px !important;
    max-width: unset !important;
    padding: 6px 15px !important;
    text-align: center !important;
    width: 354px !important;

    .tooltip-text {
        .link {
            color: #00b14f;
            font-family: Inter, sans-serif;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
        }

        .icon {
            color: #00b14f;
            font-size: 14px;
            line-height: 20px;
            transform: translateY(2px);
        }
    }
}

.info-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    .content {
        min-width: 11vh;

        .title-block {
            display: flex;
            align-items: baseline;
            justify-content: space-between;
            margin-bottom: 8px;
        }

        .title-container {
            display: flex;
            flex-direction: column;
            gap: 12px;
        }

        .title {
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            color: #212f3f;
            flex: 1;
            font-weight: 600;
            line-height: 0;
            margin-bottom: 4px;
            margin-right: 24px;
            margin-top: 0;
            overflow: hidden;
            overflow-wrap: break-word;
            text-overflow: ellipsis;

            &:hover {
                .title-link {
                    color: #00b14f;
                }
            }
        }

        .title-link {
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
        }

        .time-saved {
            color: #424e5c;
            display: block;
            font-size: 15px;
            font-weight: 400;
            line-height: 20px;
            margin-bottom: 12px;
        }

        .company-link {
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            color: #424e5c;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            margin-top: 0;
            overflow: hidden;
            overflow-wrap: break-word;
            text-overflow: ellipsis;
            width: fit-content;
        }

        .salary {
            flex-shrink: 0;
            min-width: 150px;
            overflow: hidden;
            position: relative;
            text-align: right;
            .label {
                align-items: center;
                color: #00b14f;
                display: flex;
                font-size: 14px;
                font-weight: 600;
                gap: 10px;
                justify-content: flex-end;
                line-height: 20px;
                margin-bottom: 0;
                margin-top: 0;
                white-space: nowrap;
            }
        }
    }

    .info {
        display: flex;
        align-items: flex-end;
        column-gap: 24px;
        justify-content: space-between;

        .tag-block {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 8px;

            .tag {
                background: #e9eaec;
                border-radius: 3px;
                color: #212f3f;
                font-size: 12px;
                font-weight: 400;
                line-height: 16px;
                margin-bottom: 0;
                margin-top: 0;
                padding: 4px 8px;
            }
        }

        .action-block {
            align-items: flex-end;
            display: flex;
            gap: 8px;
            .btn {
                border-radius: 3px;
                display: flex;
                font-size: 12px;
                font-weight: 400;
                height: 28px;
                line-height: 16px;
                width: max-content;
                align-items: center;
                justify-content: center;
            }

            .apply {
                background: #00b14f;
                padding: 6px 16px;
                color: #fff;

                &:hover {
                    background: #019b46;
                }
            }

            .save {
                border-radius: 3px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                height: 28px;
                padding: 0 8px;
                white-space: nowrap;
                background: #e9eaec;
                color: #424e5c;
                width: auto;

                &:hover {
                    background: #dfe1e5;
                }

                .icon {
                    font-size: 16px;
                    margin-right: 4px;
                }

                .text {
                    color: #00b14f;
                }

                .text.save {
                    color: #ac0707;
                }
            }
        }
    }
}

.zoom {
    border: 1px solid #00b14f !important;
    position: relative !important;
    &::after {
        background-color: #00b14f;
        clear: both;
        -webkit-clip-path: polygon(0 0, 0 100%, 100% 50%);
        clip-path: polygon(0 0, 0 100%, 100% 50%);
        content: '';
        height: 15px;
        left: 100%;
        position: absolute;
        top: 50% !important;
        transform: translateY(-50%);
        width: 6px;
    }
}

.zoom-out {
    .deadline {
        display: none !important;
    }
    .apply {
        display: none !important;
    }
    .title {
        margin-right: 0 !important;
    }
    .title-block {
        display: flex !important;
        flex-direction: column !important;

        .title-link {
            font-size: 14px !important;
            font-weight: 600 !important;
            letter-spacing: 0.175px !important;
            line-height: 20px !important;
        }
    }

    .salary {
        margin-top: 8px !important;
        text-align: left !important;
        .label {
            display: inline-block !important;
            background: #e5f7ed !important;
            padding: 2px 12px !important;
        }
    }
}

@media screen and (max-width: 768px) {
    .apply,
    .salary {
        display: none !important;
    }

    .logo-container {
        height: 78px !important;
        padding: 3px !important;
        width: 78px !important;
    }

    .title-link {
        font-size: 15px !important;
        font-weight: 700 !important;
        line-height: 22px !important;
    }

    .company-link {
        font-size: 12px !important;
        line-height: 18px !important;
    }
}
