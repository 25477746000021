.btn {
    position: fixed;
    bottom: 20px;
    right: 20px;
    height: 36px;
    width: 36px;
    font-size: 20px;
    border-radius: 5px;
    z-index: 1;
    background-color: #00b14f;
    display: none;
    color: #fff;

    &:hover {
        background-color: #088641;
    }

    .icon {
        font-size: 16px;
    }
    .text {
        font-size: 10px;
        transform: translateY(-4px);
    }
}

.visible {
    display: block !important;
}
