.wrapper * {
    font-family: 'Inter', sans-serif;
}

.container {
    position: relative;
    background-color: #f5f8fa;
    border-radius: 5px;
    border: 1px solid #e8edf2 !important;
    margin-bottom: 16px;
}

.location-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e8edf2;
    padding: 23px;

    .location-box__province {
        width: 75%;
        display: flex;
    }

    .location-box__province-title {
        width: 100px;
        display: flex;
        align-items: center;
        text-align: left;
        .title-icon {
            font-size: 14px;
            color: #00b14f;
            margin-right: 8px;
        }

        .title-text {
            font-size: 14px;
            line-height: 21px;
            font-weight: 500;
            color: #303235;
        }
    }

    .location-box__province-content {
        width: calc(0.75 * (100% - 100px));
    }

    .remove-location {
        position: absolute;
        right: 24px;
        top: 30px;
        cursor: pointer;

        .remove-icon {
            color: #da4538;
        }
    }
}

.address-box {
    .address-box__district {
        padding: 23px;
    }

    .add-district {
        color: #00b14f;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.5;
        display: flex;
        align-items: center;
    }

    .add-icon {
        margin-right: 8px;
    }
}
.box-skeleton {
    padding: 23px;
}
