.wrapper {
    border: 1px solid #e8edf2;
    padding: 20px;
}

.wrapper * {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
}

.input-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 14px;

    .input-box {
        width: 90%;
        position: relative;
    }

    .icon {
        position: absolute;
        z-index: 2;
        width: 32px;
        height: 32px;
        line-height: 32px;
        pointer-events: none;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .icon-search {
    }

    .input {
        border-radius: 20px;
        width: 100%;
        height: 34px;
        padding: 6px 12px 6px 32px;
        border: 1px solid #e8edf2;
        transition: all 0.15s ease-in-out;

        &:focus {
            border-color: #00b14f;
        }
    }

    .btn-search {
        margin-left: 8px;
    }

    .btn {
        min-width: 100px;
        background: #00b14f;
        border-radius: 20px;
        border: none;
        color: #fff;
        padding: 6px 12px;

        &:hover {
            background: #00652d;
        }
    }
}

.description {
    margin-bottom: 16px;
}

.company-list {
    display: flex;
    flex-wrap: wrap;

    .company {
        width: 100%;
        max-width: 50%;
    }
}
.read-more {
    text-align: center;
    .btn {
        color: #303235;
        background: #f5f8fa;
        border: 1px solid #e8edf2;
        border-radius: 5px;
        padding: 6px 12px;
        margin-top: 30px;
        transition: all 0.15s ease-in-out;
        min-width: 100px;
        &:hover {
            background: #d0dae4;
        }
    }
}
