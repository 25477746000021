.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.spinner {
    border-width: 6px;
    border-style: solid;
    border-color: #ab8cff;
    border-top: 6px solid transparent;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: relative;
    animation: spin 2s infinite;
    border-top-color: transparent !important;
    @keyframes spin {
        100% {
            transform: rotate(360deg);
        }
    }
}
