
    .table-head {
        .table-head-item {
            padding: 16px;
            font-weight: 500;
            white-space: nowrap;
            font-size: 14px;
            line-height: 1.5;
            text-align: left;
        }
        .table-head-item:not(:last-child) {
            // border-right: 1px solid #e8edf2;
        }

        .table-head-item:nth-child(1) {
            width: 60px;
        }

        .table-head-item:nth-child(2) {
            min-width: 100px;
            max-width: 100px;
        }


        .table-head-item:nth-child(3) {
            width: 200px;
        }

        .table-head-item:nth-child(4) {
            min-width: 350px;
            max-width: 350px;
        }

        // .table-head-item:nth-child(5) {
        //     width: 140px;
        // }

        // .table-head-item:nth-child(6) {
        //     width: 240px;
        // }
    }

    .table-body-row {
        &:hover {
            .item-content-absolute {
                visibility: visible !important;
            }
        }
    }

    .table-body-item {
        // display: inline-block;
        // min-height: 150px;
        padding: 16px;
        vertical-align: top;
        border-top: 1px solid #e8edf2;
        // border-right: 1px solid #e8edf2 !important;
        font-size: 14px;
        line-height: 1.5;
        font-weight: 500;

        .id {
            color: #5e6368;
            font-weight: 400;
        }

        .item-content-title {
            color: #000 !important;
            width: 80%;
            margin: 4px 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .item-content-absolute {
            // display: block;
            display: flex;
            align-items: center;
            margin-top: 8px;
            visibility: hidden;
        }
    
        .item-avatar {
            display: flex;

            .item-switch {
                position: relative;
                display: inline-block;
                width: 24px;
                min-width: 24px;
                height: 16px;
                margin-right: 4px;
            }

            .switch-input {
                opacity: 0;
                width: 0;
                height: 0;
            }

            .switch-slider {
                cursor: pointer;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: #c2c9d1;
                border-radius: 34px;
                position: absolute;
                transition: 0.4s;

                &::before {
                    border-radius: 50%;
                    content: '';
                    height: 12px;
                    width: 12px;
                    left: 2px;
                    bottom: 2px;
                    background-color: #fff;
                    position: absolute;
                    transition: 0.4s;
                }
            }

            .switch-input:checked + .switch-slider {
                background-color: #00b14f;
            }

            .switch-input:checked + .switch-slider::before {
                transform: translateX(8px);
            }

            .item-content {
                margin-left: 8px;
            }

            a:hover {
                color: #00b14f !important;
                text-decoration: underline;
            }

            .item-content-image{
                width: 80px;
                height: 80px;
                border: 1px solid #e8edf2;
                border-radius: 50%;
                overflow: hidden;
                margin-right: 8px;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        .item-name {
            display: flex;
            align-items: flex-start;
            flex-direction: column;

            a {
                // font-size: 12px;
                color: #2d7cf1 !important;

                &:hover {
                    color: #0056b3 !important;
                    text-decoration: underline;
                }
            }

            .status{
                display: inline-block;
                color: #5e6368 !important;
                background: #e8edf2;
                font-size: 12px;
                line-height: 1;
                font-weight: 400;
                text-align: center;
                white-space: nowrap;
                vertical-align: baseline;
                padding: 3px 7px;
                border-radius: 140px;
                margin: 4px 4px 4px 0;

                &.active {
                    background: #f2daff;
                    color: #6514e9;
                    font-weight: 500;
                }
            }
        }

        .info-business {
            .item-content-info{
                gap: 4px;
                margin: 4px 0;
                display: flex;
                align-items: center;
            }

            .icon{
                font-size: 20px;
                color: #a8afb6;
            }
            a {
                color: #000 !important;
                margin-left: 4px;

                &:hover {
                    text-decoration: underline !important;
                }

                .icon-edit {
                    color: #5e6368 !important;
                    font-size: 14px;
                }
            }
        }

        .company-apply {
            display: block;
            .company-apply-number {
                display: inline-block;
                color: #5e6368 !important;
                background: #e8edf2;
                font-size: 12px;
                line-height: 1;
                font-weight: 400;
                text-align: center;
                white-space: nowrap;
                vertical-align: baseline;
                padding: 3px 7px;
                border-radius: 140px;
                margin: 4px 0;

                &.active {
                    background: #f2daff;
                    color: #6514e9;
                    font-weight: 500;
                }
            }
            .item-content-title{
                color: #000 !important;
                width: 80%;
                margin: 4px 0; 
                display: -webkit-box;
                line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: normal;

            }
            .item-content-des{
                color: #5e6368 !important;
                width: 80%;
                margin: 4px 0;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;             
            }

            .item-content-info{
                gap: 4px;
                margin: 4px 0;
                display: flex;
                align-items: center;
            }

            .icon{
                font-size: 20px;
                color: #a8afb6;
            }
            .company-apply-view {
                color: #343a40 !important;
                // margin-top: 8px;
                line-height: 1.5;

                a {
                    display: flex;
                    align-items: center;
                }

                .company-apply-view-icon {
                    font-size: 14px;
                    color: #a8afb6;

                    margin-right: 4px;
                }
            }
        }
    }

    .table-body-item-campaign {
        display: flex;
        flex-direction: column;

        .item-content-title {
            color: #000 !important;
            width: 80%;
            margin: 4px 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .item-content-id {
            color: #5e6368;
            font-weight: 400;
        }
    }

    .table-body-empty {
        text-align: center;
        padding: 16px;
        vertical-align: top;
        border-top: 1px solid #e8edf2;
        border-right: 1px solid #e8edf2 !important;
        font-size: 14px;
        line-height: 1.5;
        font-weight: 500;
    }

    .table-body-item:nth-child(1){
        max-width: 128px;
    }

    .table-body-item:nth-child(2){
        width: 180px;
    }

    .table-body-item:nth-child(3){
        // max-width: 300px;
    }
    .table-body-item:nth-child(4){
        max-width: 350px;
    }

    .table-body-item-status {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .item-content-status {
            display: inline-block;
            color: #5e6368 !important;
            background: #e8edf2;
            font-size: 14px;
            line-height: 1;
            font-weight: 400;
            text-align: center;
            white-space: nowrap;
            vertical-align: baseline;
            padding: 6px 12px;
            border-radius: 140px;
            margin: 4px 4px 4px 0;
        }


        .item-content-status-pending {
            background: #d6bbff;
            color: #5c2a9d;
            font-weight: 500;
        }
        
        .item-content-status-reject {
            background: #ff6f6f;
            color: #b83232;
            font-weight: 500;
        }
        
        .item-content-status-approved {
            background: #28a745;
            color: #fff !important;
            font-weight: 500;
        }
        
        .item-content-status-interview {
            background: #ffe0ff;
            color: #9c43bf;
            font-weight: 500;
        }
        
        .item-content-status-viewed {
            background: #e3f2ff;
            color: #1976d2;
            font-weight: 500;
        }
    }

    .item-content-update-status {
        position: relative;
        margin-left: 8px;
    }
    
    .icon {
        cursor: pointer;
        font-size: 24px;
        color: #333;
        transition: color 0.3s ease;
    }
    
    .icon:hover {
        color: #007bff;
    }
    
    .item-content-update-status-list {
        position: absolute;
        top: 100%;
        right: 0;
        background-color: white;
        border: 1px solid #ddd;
        border-radius: 4px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        width: 200px;
        max-height: 0;
        opacity: 0;
        overflow: hidden;
        transition: all 0.3s ease;
        z-index: 10;
    }
    
    .item-content-update-status-list.show {
        max-height: 300px;
        opacity: 1;
    }
    
    .item-content-update-status-item {
        padding: 10px 20px;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }
    
    .item-content-update-status-item:hover {
        background-color: #f5f5f5;
    }
    
    .item-content-update-status-item-value {
        font-size: 14px;
        color: #333;
    }
    

.footer {
    margin: 0 auto;
    margin-bottom: 50px;

    .content-footer {
        align-items: center;
        display: flex;
        gap: 10px;
        justify-content: center;

        .btn {
            align-items: center;
            aspect-ratio: 1/1;
            border: 1px solid #00b14f;
            border-radius: 50%;
            color: #00b14f !important;
            cursor: pointer;
            display: flex;
            justify-content: center;
            width: 28px;
            height: 28px;
        }

        .deactive {
            border: 1px solid #dee0e2;
            color: #dee0e2 !important;
            cursor: unset;
            opacity: 1;
            pointer-events: none;
        }

        .btn:hover {
            background: #00b14f;
            color: #fff;

            .icon {
                color: #fff;
            }
        }

        .icon {
            font-size: 20px;
        }

        .number {
            color: #00b14f;
        }

        .text-page {
            color: #a6acb2;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
        }
    }
}
