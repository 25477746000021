.wrapper {
    background: #f2f2f2;
    box-sizing: border-box;
    padding: 0px 16px;
    position: relative;
}

@media screen and (min-width: 1340px) {
    .wrapper {
        display: none;
    }
}

@media screen and (max-width: 1339px) {
    .wrapper {
        display: block !important;
    }

    .navbar-mobile {
        display: flex !important;
    }
}

.container {
    max-height: 0;
    transition: all 0.3s ease;
    z-index: 0;
    overflow: hidden;
}

.down .container {
    max-height: 150vh;
}

.navbar-mobile {
    position: absolute;
    right: 10px;
    top: -60px;
    z-index: 99;
    box-sizing: content-box;
    color: #00b14f !important;
    border-radius: 50%;
    background: rgba(33, 47, 63, 0.102) !important;
    display: none;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;

    .icon-menu {
        display: block;
        font-size: 28px;
    }

    .bars {
        font-size: 20px;
    }
}

.title {
    color: #4d5965;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 8px;
    margin-top: 8px;
}

.nav-item {
    margin-bottom: 8px;
    position: relative;
    // height: 48px;
    min-height: 48px;
}

.content {
    background: #fff;
    border-radius: 6px;
    padding: 14px 16px;
    align-items: center;
    // position: relative;
    display: flex;
}

.container-nav {
    flex-grow: 1;
    height: 20px;
}

.link {
    color: #00b14f;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
}

.btn-expand {
    font-size: 15px;
    transition: all 0.3s ease;
}

.btn-expand.active {
    transform: rotate(180deg);
}

.icon {
    width: 28px;
    height: 28px;
    position: absolute;
    transform: translate(10px, -10px);
}

.show .menu {
    opacity: 1 !important;
    max-height: 88vh !important;
}

.menu {
    opacity: 0;
    display: block;
    z-index: 2;
    margin-top: -9px;
    // position: absolute;
    top: 100%;
    max-height: 0;
    overflow: hidden;
    transition: opacity 0.3s ease, max-height 0.6s ease;
    box-shadow: 1px 2px 20px rgba(0, 0, 0, 0.06);
}

.account-content {
    margin-bottom: 10px;
    .account-link {
        align-items: center;
        display: flex;
    }
    .account {
        height: auto;
        // margin-bottom: 10px;
    }

    .avatar {
        border-radius: 50%;
        height: 32px;
        margin-right: 20px;
        width: 32px;
    }

    .info {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .name {
        color: #00b14f;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
    }

    .detail {
        color: #6f7882;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
    }

    .code {
        color: #212f3f;
        font-weight: 500;
    }

    .email {
        color: #6f7882;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
    }
}
