.wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 11px 0;
    gap: 24px;
}

// .col-1 {
//     width: 20%;
// }

// .col-2 {
//     width: 5%;
// }

// .col-3 {
//     width: 20%;
// }

// .col-4 {
//     width: 15%;
// }

// .col-5 {
//     width: 10%;
// }

// .col-6 {
//     width: 20%;
// }

.col-1 {
    width: 30%;
}

.col-2 {
    width: 5%;
}

.col-3 {
    width: 30%;
}

.col-4 {
    width: 20%;
}

.col-5 {
    width: 10%;
}

.col-6 {
    width: 20%;
}
