.wrapper {
    width: 100%;
}

.container {
    padding-bottom: 10px;
    background: #fff 0 0 no-repeat padding-box;
    border-radius: 5px;
    height: 390px;
    margin-bottom: 24px;
    transition: all 0.15s ease-in-out;
    box-shadow: -1px 1px 4px rgba(0, 0, 0, 0.051);
    overflow: hidden;
    &:hover {
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.07) !important;
    }
}

.company-banner {
    height: 180px;
    margin-bottom: 16px;
    overflow: hidden;
    position: relative;
    border-radius: 5px;

    .company-banner-container {
        height: 150px;
    }

    .company-banner-image {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    .company-logo-container {
        background: #fff;
        border: 1px solid #eee;
        border-radius: 5px;
        bottom: 0;
        height: 64px;
        width: 64px;
        left: 16px;
        position: absolute;
    }

    .company-logo {
        height: auto;
        max-width: 100%;
    }
}

.company-info {
    padding: 0 16px;
    .company-info-name {
        font-size: 14px;
        line-height: 1.5;
        font-weight: 600;
        text-transform: uppercase;

        .company-info-name-link {
            &:hover {
                text-decoration: underline;
            }
        }
    }

    .company-info-description {
        padding-top: 16px;
    }

    .company-info-description-text {
        display: -webkit-box !important;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
        margin-bottom: 10px;
        // height: 120px;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #555;
        font-size: 14px;
        line-height: 1.5;
    }
}
