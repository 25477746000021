.wrapper {
    width: 100%;
}

@media screen and (max-width: 768px) {
    .title {
        font-size: 16px !important;
        font-weight: 600 !important;
        letter-spacing: -0.16px !important;
        line-height: 24px !important;
    }
}

.container {
    padding: 20px;
    background: #fff;
    margin-bottom: 16px;
    width: 100% !important;
}

.title {
    color: #212f3f;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.2px;
    line-height: 28px;
    margin-bottom: 12px;
}

.job-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 12px;
}

.link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    gap: 6px;
    margin: 0 auto;

    &:hover {
        text-decoration: underline;
    }

    .text {
        color: #00b14f;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
    }

    .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 24px;
        width: 24px;
        padding: 4px;

        .icon-arrow {
            color: #00b14f;
            font-size: 15px;
            line-height: 20px;
        }
    }
}
