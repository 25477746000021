.wrapper *{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.wrapper {
    height: calc(100vh - 75px);
}


.conversation-name{
    font-size: 15px;
    font-weight: 500;
    color: rgb(5, 5, 5);
    line-height: 20px;
}

.conversation-info{
    font-size: 13px;
    font-weight: 400;
    color: rgb(101, 103, 107);
    line-height: 16px;
    display: flex;
    align-items: center;
}

.conversation-info-icon{
    font-size: 2px;
    color: rgb(101, 103, 107);
    margin: 0 4px;
}

.avatar-group-conversation{
    width: 68px !important;
    align-items: center;
    justify-content: center;
    padding-top: 0 !important;
}

.avatar {
    border: solid 1px rgb(189, 189, 189);
}

.sidebar {
    max-width: 440px !important;
}

.expansion-panel {
    font-size: 18px;
    color: rgb(52, 58, 64);
    font-weight: 700;
    margin: 8px 0;
    text-transform: uppercase;
}

.image-content{
    // max-width: 50px;
    // max-height: 50px;
    // border-radius: 50%;
    // margin-right: 10px;
}

.list-attachment {
    position: absolute;
    bottom: 70px;
    left: 0;
    right: 0;
    margin-left: 29%;
    gap: 5px;
    flex-direction: row;
    max-height: 100px;
    z-index: 100;
}

.image-message {
    position: relative;

    .image {
        max-width: 300px;
        max-height: 300px;
        cursor: pointer;
        border-radius: 16px;
    }


    .image-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 20px;
        font-weight: 700;
        cursor: pointer;
        border-radius: 16px;
    }
}


.file-link {
    display: flex;
    align-items: center;
    gap: 5px;
    border-radius: 10px;
    cursor: pointer;
    max-width: 250px;

    .file-name {

    }

    .file-size {
        font-size: 12px;
        color: rgb(101, 103, 107);
    }
    
    .file-icon {
        padding: 5px;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        min-width: 40px;
        min-height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgb(230, 230, 230);
        margin-right: 10px;
        .icon {
            font-size: 20px;
            color: rgb(101, 103, 107);
        }
    }
}


@media screen and (min-width: 1700px) {
    .list-attachment {
        margin-left: 25%;
    }
}

