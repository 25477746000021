.wrapper {
}

.wrapper * {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    color: #303235;
}

.container {
    padding: 0 16px;
}

.header {
    padding: 11px 0;
}

.body {
    border: 1px solid #e8edf2;
    padding: 16px 18px;
    background-color: #fff;
}

.body-item {
    display: flex;
    margin-bottom: 14px;
}

.label {
    width: 100%;
    float: left;
    font-weight: 600;
    padding: 6px 15px 6px 0;
    max-width: 25%;
    width: 100%;
}

.input-box {
    position: relative;
    max-width: 75%;
    width: 100%;
}

.icon-warning {
    position: absolute;
    top: 10px;
    right: 16px;
    color: #00b14f;
}

.input {
    padding: 10px 12px;
    padding-right: 40px;
    height: 33.5px;
    color: #000;
    display: block;
    width: 100%;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e8edf2;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:focus {
        border-color: #00b14f;
        outline: 0;
        box-shadow: 0 0 0 0.1rem rgba(0, 177, 79, 0.25);
    }
}

.message-error {
    width: 100%;
    margin-top: 0.25rem;
    color: #dc3545;
    font-weight: 500;
    .message {
        color: #dc3545;
    }
}

.button {
    min-width: 100px;
    border-radius: 5px;
    border: 1px solid transparent;
    padding: 8px 14px !important;
    display: inline-block;
    text-align: center;

    &.cancel {
        margin-right: 10px;
        background: #f5f8fa;

        &:hover {
            background-color: #d0dae4;
        }
    }

    &.save {
        background-color: #00b14f;
        color: #fff;

        &:hover {
            background-color: #008b3e;
            border-color: #007e38;
        }

        &:disabled {
            background-color: #e8edf2;
            color: #868d94;
            border-color: #e8edf2;
            pointer-events: none;
        }
    }
}
