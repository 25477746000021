.wrapper {
    min-width: 1076px;
    min-height: calc(100vh + 1px);
    padding-left: 0;
    padding-top: 64px;
    // margin-left: 260px;
    height: 100%;
    transition: all 0.3s ease-in-out;
    background-color: #e8edf2;
}

// @media screen and (max-width: 1360px) {
//     .wrapper {
//         margin-left: 64px !important;
//     }
// }

.auth-modal {
    left: 3%;
    top: 10%;
    max-width: 450px;
    position: fixed;
    background: #fff;
    border: 1px solid #e8edf2;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    z-index: 9999;
}

.container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}

.breadcrumb-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.3s ease-in-out;
    position: sticky;
    top: 64px;
    z-index: 99;
    background: #fff;
    height: 64px;
    padding-left: 20px;
    padding-right: 20px;
    border-bottom: 1px solid #e8edf2;
    line-height: 1.5;

    .breadcrumb-box-title {
        display: flex;
        align-items: flex-start;
        flex-direction: column;

        .breadcrumb {
            font-size: 16px;
            font-weight: 500;
            line-height: 1.2px;
            color: #303235;
        }

        .breadcrumb-box-subtitle {
            display: flex;
            align-items: center;
            .icon {
                font-size: 18px;
                margin-right: 6px;
                color: #00b14f;
            }

            .breadcrumb-link {
                color: #00b14f;
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;
                cursor: pointer;
                transition: all 0.3s ease-in-out;

                &:hover {
                    color: #00652d;
                    text-decoration: underline;
                }
            }
        }
    }
}

.content {
    max-width: 1296px;
    padding: 20px 20px 80px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}

.content-wrapper {
    background: #fff;
    font-weight: 500;
    display: flex;
}

.nav-link {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.075) !important;
    flex: 1;
    width: 100px;
}

.link {
    display: none;
}

.link.active {
    display: block !important;
}
