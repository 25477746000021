.container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: relative;
    background: #fff;
    border-radius: 8px;
    flex-direction: column;
    height: fit-content;
    padding: 20px 24px;
    width: 100%;
}

.title {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    color: #263a4d;
    display: -webkit-box;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
}

.check {
    margin-left: 4px;
    display: inline-block;
    transform: translateY(4px);
    .icon-check {
        color: #00b14f;
        font-size: 20px;
    }
}

.tooltip {
    max-width: 300px;
    text-align: left;
    padding: 3px 8px;
    color: #fff;
    background-color: #000;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;

    .icon {
        font-size: 14px;
        color: #00b14f;
        margin-right: 4px;
    }
}

.sections {
    align-items: center;
    display: flex;

    .section {
        align-items: center;
        display: flex;
        gap: 16px;
        width: calc(100% / 3);

        .icon-wrapper {
            align-items: center;
            background: linear-gradient(11deg, #00bf5d, #00907c);
            border-radius: 30px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            height: 40px;
            justify-content: center;
            padding: 10px;
            width: 40px;

            .icon {
                height: 24px;
                width: 24px;
            }
        }

        .info {
            display: flex;
            flex-direction: column;
            gap: 2px;

            .label {
                color: #263a4d;
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 0.14px;
                line-height: 22px;
            }

            .text {
                color: #212f3f;
                font-size: 14px;
                font-weight: 600;
                letter-spacing: 0.175px;
                line-height: 22px;
            }
        }
    }
}

.flex {
    align-items: center;
    display: flex;
    gap: 18px;

    .quantity-applier,
    .deadline-applier {
        align-items: center;
        border: 1px solid #99e0b9;
        border-radius: 4px;
        cursor: unset;
        display: flex;
        gap: 6px;
        padding: 2px 8px;
        width: fit-content;
    }

    .deadline-applier {
        background: #f2f4f5;
        border-color: transparent;
    }
    .disabled {
        background: #f2f4f5;
        border-color: transparent;
        opacity: 1;
        pointer-events: none;
    }
    .quantity-icon,
    .deadline-icon {
        align-items: center;
        display: flex;
        height: 24px;
        justify-content: center;
        width: 24px;
    }

    .icon-lock,
    .icon-clock {
        color: #7f878f;
    }

    .text {
        color: #263a4d;
        font-weight: 400;
        align-items: center;
        display: flex;
        font-size: 14px;
        line-height: 22px;
        text-align: left;
    }
}

.company {
    align-items: center;
    align-self: stretch;
    border: 1px solid #e9eaec;
    border-radius: 6px;
    display: none;
    flex-direction: row;
    gap: 12px;
    padding: 10px;
}

.save-job {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin-top: 4px;

    .button-apply,
    .button-save {
        color: #fff;
        width: calc(100% - 144px);
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        display: flex;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        gap: 6px;
        height: 40px;
        line-height: 22px;
    }

    .button-apply {
        background: #00b14f;

        &:hover {
            background: #019b46;
        }
    }

    .button-save {
        background: #fff;
        border: 1px solid #99e0b9;
        color: #00b14f;
        width: 130px;

        &:hover {
            border-color: #00b14f;
        }
    }

    .icon-save {
        align-items: center;
        display: flex;
        height: 24px;
        justify-content: center;
        padding: 4px;
        width: 24px;

        .icon {
            font-size: 15px;
            line-height: 20px;
        }

        .icon-save {
            color: #00b14f;
            padding: 0;
        }
    }
}

@media screen and (max-width: 767px) {
    .container {
        padding: 20px 16px;
    }

    .sections {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
    }

    .section {
        width: 100% !important;
        flex-direction: row !important;
    }

    .flex {
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;

        .quantity-applier,
        .deadline-applier {
            width: 100%;
        }
    }

    .save-job {
        .button-apply {
            flex: 1;
        }

        .button-save {
            width: 40px !important;

            .text {
                display: none !important;
            }
        }
    }

    .company {
        display: flex;
        .logo-link {
            align-items: center;
            border: 1 solid #e9eaec;
            border-radius: 5px;
            display: flex;
            height: 48px;
            justify-content: center;
            padding: 4px;
            width: 48px;

            .logo {
                object-fit: contain;
                width: 100%;
            }

            .detail {
                width: calc(100% - 62px);
            }
        }

        .company-link {
            color: #212f3f;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            letter-spacing: 0.175px;
            line-height: 22px;
            margin-bottom: 4px;
        }
    }

    .info {
        width: calc(100% - 40px) !important;
        flex-direction: row !important;
    }
}

.save-job.disabled {
    .button-apply {
        background: #fff1f0;
        border-color: transparent;
        color: #d83324;
        pointer-events: none !important;

    }

    .button-save {
        background: #00b14f;
        border-color: #00b14f;
        color: #fff;

        &:hover {
            background: #019b46;
            border-color: #019b46;
        }
    }

    .icon-save {
        .icon {
            color: #00b14f;
        }

        .icon-save {
            color: #fff;
        }
    }
}
