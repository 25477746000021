.wrapper {
    background: #f4f5f5;
    padding-top: 24px;
}

@media screen and (min-width: 768px) {
    .container {
        padding-right: 15px;
        padding-left: 15px;
    }
}

.banner {
    display: flex;
    align-items: center;
    background: linear-gradient(90deg, #263238, #00b14f 105.53%);
    border-radius: 5px 5px 0 0;
    box-shadow: -1px 1px 6px rgba(0, 0, 0, 0.05);
    min-height: 180px;
    padding: 32px 16px;
    position: relative;

    .title {
        color: #fff;
        font-size: 24px;
        font-weight: 700;
        line-height: 30px;
        margin: 0 0 8px;
    }

    .description {
        color: #fff;
        font-size: 15px;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 0;
        width: 85%;
    }

    .image {
        position: absolute;
        right: 220px;
        top: 0;
        z-index: 1;
    }
}

.box-job-total {
    color: #333;
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 12px;
}

.container {
    width: 100%;
    background: #f4f5f5;
    position: relative;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-wrap: wrap;
}

.content {
    width: 75%;
    padding: 0 15px;

    .box-group {
        background: #fff;
        border-radius: 5px;
        box-shadow: -1px 1px 6px rgba(0, 0, 0, 0.05);
        margin-bottom: 16px;
        padding: 16px;
        padding-bottom: 50px;

        .empty {
            padding: 26px 0 32px;
            text-align: center;

            p {
                margin-bottom: 22px;
                margin-top: 30px;
            }

            .btn {
                background: #00b14f;
                border-radius: 5px;
                color: #fff;
                display: inline-block;
                font-weight: 700;
                padding: 9px 20px;

                &:hover {
                    background: #019b46;
                }
            }
        }
    }

    .box-group-filter {
        margin-bottom: 12px;
        .group-filter {
            border-bottom: 1px solid #eee;
            border-top: 1px solid #eee;
            display: flex;
            padding: 16px 0;

            .filter-title {
                color: #999;
                font-size: 15px;
                margin-right: 24px;
            }

            .filter-item {
                margin-right: 45px;
                display: flex;
                align-items: center;

                &:hover {
                    cursor: pointer;
                }
            }

            .icon {
                color: #ccc;
                cursor: pointer;
                margin-right: 8px;
            }

            .icon-active {
                color: #00b14f !important;
                display: none !important;
            }

            .active .icon-active {
                display: block !important;
            }

            .active .icon-disabled {
                display: none !important;
            }
        }
    }

    .box-group-body {
        .box {
            margin-bottom: 30px;
        }
    }
}

.sidebar {
    width: 25%;
    padding-right: 15px;
    padding-left: 15px;

    .title {
        color: #212f3f;
        font-size: 20px;
        font-weight: 700;
        line-height: 28px;
        margin: 0 0 16px;
    }
}

@media (min-width: 1200px) {
    .container {
        width: 1170px;
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .container {
        width: 970px;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .container {
        width: 750px;
    }
}

@media screen and (max-width: 767px) {
    .wrapper {
        min-width: 360px !important;
    }
    .container {
        padding: 0;
    }
}

.footer {
    margin: 0 auto;
    transform: translateY(-60px);

    .content-footer {
        align-items: center;
        display: flex;
        gap: 10px;
        justify-content: center;

        .btn {
            align-items: center;
            aspect-ratio: 1/1;
            border: 1px solid #00b14f;
            border-radius: 50%;
            color: #00b14f !important;
            cursor: pointer;
            display: flex;
            justify-content: center;
            width: 28px;
            height: 28px;
        }

        .deactive {
            border: 1px solid #dee0e2;
            color: #dee0e2 !important;
            cursor: unset;
            opacity: 1;
            pointer-events: none;
        }

        .btn:hover {
            background: #00b14f;
            color: #fff;

            .icon {
                color: #fff;
            }
        }

        .icon {
            font-size: 20px;
        }

        .number {
            color: #00b14f;
        }

        .text-page {
            color: #a6acb2;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
        }
    }
}
