.wrapper {
    background: #fff;
    margin: 0;
    max-height: 88vh;
    overflow: auto;
    padding: 8px 16px;
    box-shadow: 1px 2px 20px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    perspective: 1000px;
    width: auto;
    overflow: hidden;
}
