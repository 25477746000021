.wrapper {
    width: 100%;
}

.header-select {
    padding: 12px 19px 12px 2px;
    align-items: center;
    background: #fff 0 0 no-repeat padding-box;
    border: none;
    border-radius: 6px !important;
    box-shadow: 0 3px 20px rgba(0, 0, 0, 0.06);
    width: 100%;

    .container-select {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
    }

    .icon-dollar {
        height: 24px;
        width: 24px;
        color: #6f7882;
        font-size: 24px;
        margin: 0 10px;
    }

    .result {
        color: #172530;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        padding-left: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.ul-select {
    overflow-y: auto;
    max-height: 200px !important;

    .item {
        color: #172530;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        padding: 12px 12px 12px 16px;
        position: relative;
        cursor: pointer;
    }

    .item.active {
        color: #00b14f;
    }

    .text {
        font-size: 14px;
        font-weight: 500;
        color: #172530;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .icon {
        font-size: 20px;
        color: #00b14f;
        position: absolute;
        right: 12px;
    }
    .item:hover {
        background: #f4f5f5;
    }

    .item.active .text {
        color: #00b14f;
        font-weight: 600;
    }
}

.icon-chevron {
    color: #6f7882;
    box-sizing: content-box !important;
    font-size: 22px;
    // padding-right: 10px;
    padding-left: 10px;
    // padding: 0 ;
    margin-right: 10px;
}

.header-body {
    border-bottom: 1px solid #f2f4f5;
    padding: 16px;

    .input-filter {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        .input {
            border: 1px solid #e9eaec;
            border-radius: 6px;
            font-weight: 600;
            height: 40px;
            padding: 8px 12px;
            width: 105.5px;
            font-size: 14px;
        }

        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Firefox */
        input[type='number'] {
            -moz-appearance: textfield;
        }

        .input::placeholder {
            color: #6f7882;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
        }

        .input:focus {
            border: 1px solid #d83324 !important;
        }

        .line {
            color: #263a4d;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            letter-spacing: 0.14px;
            line-height: 22px;
        }
    }

    .button-filter {
        display: inline-block;
        margin-bottom: 0;
        font-weight: 400;
        text-align: center;
        cursor: pointer;
        border: 1px solid transparent;
        font-size: 14px;
        font-weight: 600;
        height: 32px;
        padding: 0;
        width: 100%;
        border-radius: 4px;
    }

    .button-filter.active {
        background-color: #00b14f;
        color: #fff;
    }
}

@media screen and (min-width: 1200px) {
    .wrapper {
        width: 233px;
        height: 56px;
    }

    .header-select {
        padding: 16px 19px 16px 2px;
    }
}
