.wrapper {
    display: flex;
    flex-direction: row;
    width: 70%;
    min-width: 180px;
    overflow-y: hidden;
    gap: 10px;
    padding: 16px 10px;
    border-radius: 10px;
    background-color: #f0f2f5;
}