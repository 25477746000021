.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    padding-top: 64px;
    border-radius: 10px;
    background-color: #e8edf2;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
    width: 100%;
    height: 100%;
}   

.header {
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    background-color: #fff;
    justify-content: flex-end;
    align-items: center;
    padding-right: 20px;
    height: 64px;
}

.container {
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 100%;
    max-width: 80%;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 20px;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    flex: 0 0 calc(100% * 2 / 3);
    max-width: calc(100% * 2 / 3);
    margin-right: auto;
    margin-left: auto;
    padding: 20px;
    border-radius: 4px;
    background-color: #fff;
}

.path-item {
    margin-bottom: 10px;
}

.box {
    display: flex;
    flex-direction: row;
    gap: 20px;

    .box-path {
        width: 50%;
    }
}