.wrapper {
}

@media screen and (max-width: 768px) {
}

.container {
    padding: 0 15px;
}

.title {
    color: #00b14f;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    margin: 0 0 20px;
}
