.wrapper {
    height: calc(100% - 64px);
    max-height: 100%;
    position: fixed;
    z-index: 1000;
    top: 64px;
    background: #fff;
    border-right: 1px solid #e8edf2;
    transition: all 0.3s ease-in-out;
    width: 260px;
}


@media screen and (min-width: 1361px) {
    .hide {
        width: 64px;

        &:hover {
            width: 260px;

            .item-link {
                padding-left: 16px;
            }

            .btn-verify {
                padding: 3px 10px;
                width: 230px;

                .text {
                    display: flex !important;
                }

                .icon {
                    margin-right: 6px !important;
                }
            }

            .notification {
                left: calc(260px - 50px) !important;
                bottom: unset !important;
                .notification-count {
                    font-size: 12px !important ;
                    padding: 0 5px !important;
                }
            }
        }
    }

    .item-link {
        gap: 16px;
    }

    .btn-verify {
        padding: 3px 0 !important;
        width: unset !important;

        // .text {
        //     display: none !important;
        // }

        .icon {
            margin-right: 6px !important;
        }
    }

    .notification {
        right: 8px;
        bottom: 12px;
        left: auto !important;

        .notification-count {
            padding: 4px !important;
            width: 5px !important;
            height: 5px !important;
            font-size: 1px !important;
        }
    }
    
    .show {
        .notification-count {
            font-size: 12px !important ;
            padding: 0 5px !important;
        }

        .notification {
            left: calc(260px - 50px) !important;
            bottom: unset !important;
        }
    }
}

@media screen and (max-width: 1360px) {
    .wrapper {
        width: 64px;

        &:hover {
            width: 260px;

            .item-link {
                padding-left: 16px;
            }

            .btn-verify {
                padding: 3px 10px;
                width: 230px;

                .text {
                    display: flex !important;
                }

                .icon {
                    margin-right: 6px !important;
                }
            }

            .notification {
                left: calc(260px - 50px);
                bottom: unset !important;
                .notification-count {
                    font-size: 12px !important ;
                    padding: 0 5px !important;
                }
            }
        }
    }

    .item-link {
        gap: 16px;
    }

    .btn-verify {
        padding: 3px 0 !important;
        width: unset !important;

        .text {
            display: none !important;
        }

        .icon {
            margin-right: 6px !important;
        }
    }

    .notification {
        right: 8px;
        bottom: 12px;
        left: auto !important;

        .notification-count {
            padding: 4px !important;
            width: 5px !important;
            height: 5px !important;
            font-size: 1px !important;
        }
    }
}

// .wrapper-full {
// }

.container {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    height: 100%;
    position: relative;
    touch-action: scroll;
    overflow-y: auto;
    padding-bottom: 70px;
    scrollbar-width: 10px;
}

.container::-webkit-scrollbar {
    display: none;
}

.container:hover::-webkit-scrollbar {
    display: block;
    width: 10px;
}

.container::-webkit-scrollbar-thumb {
    background: #dbdbdb;
    border-radius: 10px;
}

.container::-webkit-scrollbar-track {
    background: #fff;
}

.container:hover::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.container::-webkit-scrollbar-thumb:hover {
    background: rgb(146, 146, 146) !important;
}

.sidebar__header {
    .sidebar__header-content-info {
        display: flex;
        align-items: center;
        padding: 0 16px;
        overflow: hidden;
        min-height: 64px;

        .user-avatar {
            width: 32px;
            height: 32px;
            margin-right: 16px;
            border-radius: 50%;

            img {
                border: 1px solid #e8edf2;
                width: 32px;
                height: 32px;
                border-radius: 50%;
                object-fit: cover;
            }
        }

        .user-info {
            white-space: nowrap;
            float: left;
            width: 100%;
            color: #212f3f;
            padding-top: 8px;

            .user-name {
                display: block;
                width: calc(100% - 20px);
                overflow: hidden;
                text-overflow: ellipsis;
                font-weight: 500;
                text-align: left;
                font-size: 14px;
                line-height: 1.5;
            }

            .user-role {
                display: block;
                font-size: 12px;
                line-height: 1.5;
                font-weight: 500;
            }

            .user-verify {
                font-size: 12px;
                line-height: 1.5;
                font-weight: 500;
                margin-bottom: 8px;
            }

            .user-verify-level {
                color: #00b14f;
                margin-right: 4px;
            }

            .icon {
                font-size: 15px;
                vertical-align: middle;
                color: #848885;
            }
        }
    }

    .sidebar__header-content-verify {
        padding: 3px 16px 8px 16px;
        display: flex;

        .btn-verify {
            background: #ebf3ff;
            color: #2d7cf1;
            border-radius: 100px;
            padding: 3px 10px;
            width: 230px;
            font-weight: 400;
            text-align: center;
            vertical-align: middle;
            font-size: 14px;
            line-height: 1.5;
            transition: all 0.15s ease-in-out;
            border: 1px solid transparent;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            white-space: nowrap;
        }

        .icon {
            margin: 0 12px 0 6px;
            height: 18px;
        }

        .text {
            line-height: 1.5;
            font-size: 12px;
            font-weight: 500;
            display: flex;
            align-items: center;
            white-space: nowrap;
        }

        @keyframes animationTranform {
            0% {
                transform: translateX(0);
            }
            50% {
                transform: translateX(5px);
            }
            100% {
                transform: translateX(0);
            }
        }

        .icon-right {
            font-size: 12px;
            animation: animationTranform 1s ease-in-out infinite;
            margin-left: 10px;
            position: relative;
        }
    }
}

.sidebar__body {
    padding-bottom: 10px;

    .hr {
        margin-top: 8px;
        margin-bottom: 8px;
        border-top: 1px solid #e8edf2;
    }

    .sidebar__body-item {
        padding: 8px 0;
        display: flex;
        flex-direction: column;
        transition: all 0.5s ease-in-out;

        &:hover {
            background: #e3e3ec;
        }

        .item {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .item-link {
            display: flex;
            align-items: center;
            position: relative;
            width: 100%;
            padding: 5.5px 0;
            max-height: 32px;
            color: #212f3f;
        }

        .item-icon {
            display: flex;
            text-align: center;
            width: 62px;
            margin-top: -2px;
            padding-left: 16px;
        }

        .icon-container {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .icon {
            font-size: 14px;
        }

        .item-text {
            font-size: 14px;
            line-height: 1.5;
            font-weight: 500;
            overflow: hidden;
            white-space: nowrap;
        }

        .notification {
            position: absolute;
            left: calc(260px - 50px);

            .notification-count {
                font-weight: 500;
                font-size: 12px;
                color: #fff;
                background-color: #00b14f;
                padding: 0 5px;
                text-align: center;
                margin-left: 5px;
                min-width: 18px;
                min-height: 18px;
                border-radius: 10px;
            }
        }
    }
}

.sidebar__footer {
    padding: 23px;
    padding-top: 15px;
    .footer-content {
        font-size: 12px;
        font-weight: 400;
        color: #5e6368;
        white-space: nowrap;
    }
}

.isActive {
    .item-icon,
    .item-text {
        color: #00b14f;
    }
}
