.wrapper {
}

.container {
    background: #fff;
    border: 1px solid #57d991;
    border-radius: 8px;
    overflow: hidden;
}

.banner {
    .banner-img {
        img {
            height: 68px;
            max-height: 360px;
            object-fit: cover;
            width: 100%;
        }
    }
}

.info {
    background: #f7f7f7;
    padding: 16px 16px 20px;

    .company-info {
        border-bottom: 1px solid #f2f4f5;
        display: flex;
        gap: 16px;
        padding-bottom: 16px;
        .logo {
            background: #fff;
            border: 2px solid #e6e7e8;
            border-radius: 74px;
            flex-shrink: 0;
            height: 76px;
            overflow: hidden;
            width: 76px;
            img {
                height: 100%;
                width: 100%;
                object-fit: contain;
            }
        }

        .name {
            a {
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                color: #212f3f;
                display: -webkit-box;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px;
                margin-bottom: 8px;
                overflow: hidden;
                text-decoration: none;
                word-break: break-word;
            }
        }
    }

    .job-list {
        margin-top: 11px;

        .job-item {
            padding: 12px 0;
            border-bottom: 1px solid #f2f4f5;
        }
    }
}
