.wrapper {
    box-sizing: border-box;
    position: relative;
    // padding: 24px 5px;
    z-index: 2;
    margin: 0 auto;
}

@media screen and (max-width: 768px) {
    .wrapper {
        width: calc(768px + 16px) !important;
        margin-left: -8px !important;
    }

    .img {
        border-radius: 8px !important;
        height: 100px !important;
    }
}

@media screen and (min-width: 768px) {
    .wrapper {
        width: calc(100% + 16px) !important;
        margin-left: -8px !important;
    }

    .img {
        border-radius: 8px !important;
        height: 130px !important;
    }
}

@media screen and (min-width: 992px) {
    .wrapper {
        width: calc(100% + 16px) !important;
        margin-left: -8px !important;
    }
}

@media screen and (min-width: 1200px) {
    .wrapper {
        width: calc(100% + 16px);
        margin-left: -8px;
    }

    .img {
        border-radius: 8px !important;
        height: 270px !important;
    }
}

.slide {
    width: calc(100%) !important;
}

.slider > div {
    border-radius: 4px;
}

.content {
    display: block;
    padding: 0 8px;
}

.img {
    display: inline-block;
    object-fit: cover;
    width: 100%;
    height: 200px;
}

.btn {
    position: absolute;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    width: 32px;
    height: 32px;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.08);
    background: #00b14f;
    color: #fff;
}

.btn-left {
    left: 0;
    top: 50%;
    transform: translate(-8px, -50%);
}

.btn-right {
    right: 0;
    top: 50%;
    transform: translate(8px, -50%);
}

.btn:hover {
    color: #00b14f;
    background: #fff;

    .icon {
        color: #00b14f;
    }
}

.icon {
    font-size: 16px;
}
