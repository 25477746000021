.wrapper {
    background-size: cover;
    min-height: 480px;
    padding-bottom: 64.5px;
    padding-top: 50px;
}

@media screen and (max-width: 767px) {
    .tab-container img {
        display: flex;
        height: auto !important;
        margin: 0 auto !important;
        max-width: 500px !important;
        width: 100% !important;
    }
}

@media screen and (min-width: 768px) {
    .candidate {
        display: flex;
        flex-direction: row !important;
    }

    .employer {
        display: flex;
    }
    .col {
        width: calc(100% / 3) !important;
        float: left !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .container {
        width: 750px !important;
    }

    .employer {
        flex-direction: row;
        flex-wrap: wrap;
    }
    .service {
        width: 100% !important;
        flex-direction: row !important;
        display: flex !important;
        .service-email,
        .service-phone {
            width: 50% !important;
        }
    }
    .hotline-col {
        width: 50% !important;
    }
}

@media (min-width: 992px) {
    .container {
        width: 970px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
    .tab-container .text-content {
        width: 100% !important;
    }

    .text-title {
        margin-bottom: 24px !important;
        padding: 0;
        text-align: center !important;
        width: 100% !important;
    }

    .box-phone-number {
        margin: 0 auto !important;
    }

    .box-email {
        justify-content: center !important;
    }
}

@media screen and (min-width: 1200px) {
    .tab-container .text-content {
        width: 45%;
    }

    .tab-container img {
        display: flex;
        height: auto;
        margin: 0 auto;
        max-width: 500px;
    }
}

@media screen and (max-width: 767px) {
    .tab-item {
        font-size: 12px !important;
        font-weight: 500 !important;
        height: 32px !important;
        line-height: 16px !important;
        width: 174.5px !important;
    }

    .tab-item a {
        background-size: 175px 32px;
        height: 32px;
        padding-left: 12px;
        padding-top: 10px;
        width: 175px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .text-title {
        font-size: 16px !important;
        font-weight: 600 !important;
        line-height: 25px !important;
        padding: 0 !important;
        text-align: center !important;
    }

    .box-phone-number {
        align-items: center !important;
        display: flex !important;
        height: 50px !important;
        justify-content: center !important;
        margin: 0 auto !important;
        max-width: 300px;
        width: 100%;
    }

    .phone-number,
    .phone-text {
        font-size: 14px !important;
        font-weight: 600 !important;
        line-height: 22px !important;
        padding: 14px 15px 14px 0 !important;
    }

    .phone-text {
        padding: 0 !important;
    }

    .phone-link {
        height: 38px !important;
        margin: 8px 0 !important;
        padding: 8px 15px !important;
        width: 120px !important;
    }

    .box-email {
        justify-content: center !important;
    }
}

.tab-item {
    font-size: 12px;
    font-weight: 500;
    height: 32px;
    line-height: 16px;
    width: 174.5px;
}

.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.title {
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    margin: 0 0 10px 0;
}

.tab-list {
    display: flex;
}

.tab-item:not(:first-child) a {
    left: -32px;
}

.tab-item {
    border-radius: 10px 3px 0 0;
    font-size: 16px;
    font-weight: 700;
    height: 57px;
    line-height: 24px;
    position: relative;
    width: 300px;
}

.tab-link {
    position: relative;
    display: block;
    padding: 10px 15px;
    background-image: url(../../../../assets/images/tab.svg);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 315px 57px;
    color: #a6acb2;
    display: block;
    height: 57px;
    padding-left: 32px;
    padding-top: 20px;
    width: 315px;
}

.active .tab-link {
    color: #00b14f !important;
    background-image: url(../../../../assets/images/tab_active.svg);
    z-index: 2;
}

.tab-content {
    border-radius: 0 20px 20px 20px;
}

.tab-panel {
    display: none;
    padding: 20px 0;
    background: linear-gradient(90deg, #fff -9.59%, #fff 37.78%, #deffea);
    border-radius: 0 20px 20px 20px;
    padding: 20px 40px;
}

.tab-panel.active {
    display: block;
}

.tab-container {
    margin-right: -15px;
    margin-left: -15px;

    img {
        height: 275px;
        margin-bottom: -20px;
        margin-left: 50px;
    }

    .text-title {
        font-size: 28px;
        font-weight: 700;
        line-height: 36px;
        margin-bottom: 16px;
        padding: 2px 0 0;

        .label {
            color: #212f3f;
        }

        .highlight {
            color: #00b14f;
        }
    }

    .box-phone-number {
        align-items: center;
        background: linear-gradient(90deg, #047e3c, #13c964);
        border-radius: 32px;
        box-shadow: 0 6px 20px rgba(0, 0, 0, 0.06);
        display: flex;
        height: 64px;
        margin-top: 28px;
        width: 400px;
        justify-content: space-around;

        .phone-number {
            color: #f8fefa;
            font-size: 20px;
            font-weight: 600;
            padding: 0 40px 0 24px;
        }

        .phone-link {
            align-items: center;
            background: #e9eaec;
            border-radius: 24px;
            display: flex;
            height: 48px;
            justify-items: center;
            padding: 10px 28px 10px 29px;
            transition: background 0.2s ease-out;
            width: 167px;
        }

        .phone-link:hover {
            background: #ffffff;
        }

        .phone-icon {
            font-size: 16px;
            align-items: center;
            color: #00b14f;
            display: flex;
            text-align: center;
        }

        .phone-text {
            color: #00b14f;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            padding-left: 14px;
        }
    }

    .box-email {
        align-items: center;
        display: flex;
        margin-top: 15px;

        .email {
            color: #4d5965;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            margin-right: 17px;
        }

        .email-address {
            align-items: center;
            display: flex;
        }

        .email-icon {
            background: rgba(0, 177, 79, 0.07);
            border-radius: 16px;
            height: 28px;
            margin-right: 8px;
            padding: 6px;
            width: 28px;
            color: #00b14f;
        }

        .email-link {
            color: #00b14f;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
        }
    }

    .col {
        // display: flex;
        // flex-direction: column;

        .title {
            color: #4d5965;
            font-size: 14px;
            font-weight: 700;
            line-height: 20px;
            margin: 0 0 10px;
            margin-bottom: 16px;
        }
    }

    .service-email {
        display: inline-block;
    }
}
