.wrapper {
}

.container {
}

.box-search-container,
.featured-company-container {
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
}

.box-search-company {
    background: transparent linear-gradient(6deg, #fff, #c4ffdd 100%, rgba(195, 255, 221, 0.702) 0) 0 0 no-repeat padding-box;
    margin-bottom: 0;
    min-height: 273px;
    padding-top: 24px;

    .box-search-container {
        display: flex;
    }

    .box-search {
        .box-search-nav {
            display: flex;
            padding-bottom: 33px;
            flex-wrap: wrap;
        }

        .box-search-nav-item {
            margin-right: 24px;
            position: relative;
        }

        .box-search-nav-link {
            display: block;
            border-color: #333;
            border-bottom: 2px solid transparent;
            color: #333;
            font-size: 14px;
            font-weight: 400;
            padding: 6px 0;
            transition: all 0.15s ease-in-out;

            &.active {
                font-weight: 600;
                border-color: #00b14f;
                color: #00b14f;
            }
        }

        .box-search-title {
            margin-bottom: 37px;
        }

        .box-search-title-text {
            color: #00b14f;
            font-size: 24px;
            font-weight: 500;
            line-height: 1.5;
            padding-bottom: 12px;
            text-align: left;
        }

        .box-search-title-description {
            color: #333;
            font-size: 16px;
            margin-bottom: 0;
        }

        .box-search-feature {
            display: flex;
            background: #fff 0 0 no-repeat padding-box;
            border: 1px solid #fff;
            border-radius: 100px;
            height: 50px;
            overflow: hidden;
            position: relative;
            transition: all 0.15s ease-in-out;

            &:hover {
                border-color: #00b14f;
            }

            .box-search-feature-icon {
                left: 28px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 24px;
                height: 24px;
            }

            .icon-search {
                color: #333;
                font-size: 20px;
            }

            .box-search-feature-input {
                background-color: #fff;
                border-radius: 4px;
                color: #212529;
                font-weight: 400;
                line-height: 1.5;
                padding: 4px 8px 4px 62px;
                transition: all 0.15s ease-in-out;
                border-color: transparent;
                font-size: 14px;
                height: 100%;
                outline: none;
                width: 100%;
            }

            .box-search-feature-button {
                background: #00b14f;
                border-radius: 100px;
                border: 1px solid #00b14f;
                color: #fff;
                font-size: 16px;
                line-height: 1.5;
                position: absolute;
                right: 7px;
                top: 50%;
                transform: translateY(-50%);
                text-align: center;
                padding: 4px 8px;

                transition: all 0.15s ease-in-out;

                &:hover {
                    background-color: #00883d;
                }
            }
        }
    }

    .image-search {
        margin-left: auto;
        .image-search-company {
            width: 272px !important;
        }
    }
}

.featured-company {
    .featured-company-container {
    }

    .featured-company-title {
        padding: 24px 0;
        text-align: center;
        color: #333;
        font-size: 24px;
        font-weight: 600;
        text-transform: uppercase;
    }

    .featured-company-list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 12px;
        margin-top: 24px;
    }

    .company {
        overflow: hidden;
        max-width: 100%;
    }
}

@media (min-width: 576px) {
    .box-search-container,
    .featured-company-container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .box-search-container,
    .featured-company-container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .box-search-container,
    .featured-company-container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .box-search-container,
    .featured-company-container {
        max-width: 1140px;
    }
}

@media (min-width: 1400px) {
    .box-search-container,
    .featured-company-container {
        max-width: 1320px;
    }
}

@media (max-width: 991px) {
    .featured-company-list {
        grid-template-columns: repeat(2, 1fr) !important;
    }
}

@media (max-width: 575px) {
    .featured-company-list {
        grid-template-columns: repeat(1, 1fr) !important;
    }

    .box-search-container {
        flex-direction: column;
    }
    .box-search-title-text {
        font-size: 18px !important;
    }

    .box-search-title-description {
        font-size: 14px !important;
    }

    .image-search {
        display: none !important;
    }
}

// @media (min-width: 768px) {
//     .container {
//         width: 750px;
//     }
// }
// @media (min-width: 992px) {
//     .container {
//         width: 970px;
//     }
// }

// @media (min-width: 1200px) {
//     .container {
//         width: 1170px;
//     }
// }
