.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    min-width: 1133px;
    height: 64px;
    padding: 0;
    width: 100%;
    z-index: 100;
    background-color: #212f3f;
}

.navbar {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    flex-flow: row nowrap;
    position: relative;

    .process-bar {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 4px;
        background-color: #da4538;
        z-index: 999;
    }
}

.btn-menu {
    padding: 5px 10px;
    margin-left: 15px;
    background-color: #212f3f;
    white-space: nowrap;
    border-radius: 5px;
    color: #303235;
    text-align: center;
    transition: all 0.15s ease-in-out;

    .icon-menu {
        color: #fff;
        font-size: 16px;
    }
}

.logo-link {
    display: inline-block;
    padding: 0 4px;
    margin-right: 14px;
    padding-left: 16px !important;

    .logo {
        height: 24px;
        transform: translateY(2px);
    }
}

.navbar__main {
    display: flex;
    flex-basis: auto;
    flex-grow: 1;
    align-items: center;

    .navbar__main-list {
        display: flex;
        flex-direction: row;
        margin-left: auto;
        gap: 16px;
    }

    .navbar__main-item {
    }

    .navbar__main-link {
        display: flex;
        align-items: center;
        height: 100%;
    }
    .navbar__main-wrapper {
        // height: 100%;
        border-radius: 30px;
        padding: 3px 15px;
        background-color: #3b546f;
        color: #fff;
        font-size: 14px;
        display: flex;
        align-items: center;
        gap: 5px;
        line-height: 21px;
        border: 3px solid transparent;
        transition: all 0.3s ease;
    }

    .isActive .navbar__main-wrapper {
        opacity: 0.8;
        border: 3px solid rgba(255, 255, 255, 0.3);
    }

    .icon {
        line-height: 21px;
    }

    .icon-bookmark,
    .icon-pencil {
        padding: 3px;
        border-radius: 50%;
        background-color: #fff;
        color: #212f3f;
    }

    .notification {
        width: 32px;
        height: 32px;
        position: relative;
        border-radius: 50%;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        .icon {
            padding: 0 !important;
        }
    }

    .badge {
        position: absolute;
        transform: translateY(-50%);
        background: #da4538;
        min-width: 15px;
        height: 14px;
        font-size: 12px;
        display: inline-block;
        color: #fff;
        border-radius: 6px;
        text-align: center;
        line-height: 14px;
        z-index: 999;
        padding: 0 3px;
        top: calc(50% - 8px);
        right: -8px;
    }

    .count {
        float: right;
        width: 22px;
        background: #da4538;
        margin-left: 8px;
        border-radius: 20px;
        text-align: center;
        font-size: 13px;
    }

    .text {
    }

    .navbar__main-avatar {
        width: 24px;
        height: 24px;
        border-radius: 50%;
    }

    .avatar {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
    }

    .icon-down {
        margin-left: 4px;
        font-size: 18px;
    }

    .menu-avatar {
        position: relative;
        padding: 2px 4px;
        margin-right: 10px;
        gap: 0;
    }

    .navbar__main-dropdown {
        position: absolute;
        right: 10px;
        bottom: -54px;
        box-shadow: -3px 3px 6px rgba(0, 0, 0, 0.2);
        background-color: #fff;
        border-radius: 4px;
        max-height: 0;
        opacity: 0;
        overflow: hidden;
        transition: all 0.3s ease;

        &.show {
            max-height: 200px;
            opacity: 1;
        }
    }

    .navbar__main-dropdown-item {
        font-size: 14px;
        padding: 8px 16px;
        width: 100px;
        height: 40px;
        z-index: 1000;

        &:hover {
            background-color: #f0f0f0;
        }
    }
}
