.wrapper {
    box-sizing: content-box;
    display: flex;
    align-items: center;
    background-color: #fff;
    margin: 0.5em 0;
    border: 1px solid;
    padding: 6px;
    min-width: 250px;
    border-left: 6px solid transparent;
    border-radius: 2px;
    max-width: 400px;
    height: 40px;
    pointer-events: none;
    box-shadow: 0 0 9px rgba(0, 0, 0, 0.25);
    transition: all linear 0.3s;
    animation: all 0.3s ease-in-out;
    animation: slideInLeft 0.3s ease-in-out, fadeOut 0.5s ease-in-out 2.5s forwards;
    position: relative;
}

.container {
    display: flex;
}

.icon {
    font-size: 16px;
    margin-right: 10px;
    display: flex;
    align-items: center;
}

.title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
}

.message {
    font-size: 13px;
    margin-bottom: 5px;
}

.progress-bar-left {
    position: absolute;
    left: 0;
    top: 0;
    width: 6px;
    opacity: 0.5;
    transform: translateX(-6px);
}

.progress-bar-bottom {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    opacity: 0.5;
}

.progress {
    transition: all 0.3s ease;
}

.wrapper-success {
    border-left: 4px solid #71be34;
    .btn-success {
        color: #71be34;
    }

    .progress {
        background-color: #71be34;
    }
}

.wrapper-warning {
    border-left: 4px solid #ff9f00;
    .btn-warning {
        color: #ff9f00;
    }
    .progress {
        background-color: #ff9f00;
    }
}

.wrapper-error {
    border-left: 4px solid #ff0000;
    .btn-error {
        color: #ff0000;
    }
    .progress {
        background-color: #ff0000;
    }
}

@keyframes slideInLeft {
    from {
        opacity: 0;
        transform: translateX(calc(100% + 32px));
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        display: none;
    }
}
