.wrapper {
    background: #f3f5f7 !important;
}
@media (max-width: 767px) {
    .result {
        display: inline-block;
        margin-left: 22px;
    }
}

@media (max-width: 550px) {
    .feature-job__filter {
        flex-direction: column !important;
        gap: 10px;
        align-items: flex-start !important;
    }
    .box-smart-location {
        max-width: 100% !important;
    }

    .text-guide {
        .text {
            width: 64px !important;
        }
    }

    .box-tool {
        gap: 5px !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .container {
        max-width: 750px;
    }

    .feature-job__item {
        width: calc(100% / 2 - 11px) !important;
        float: left;
    }
    .feature-job__item:nth-child(2n + 1) {
        margin-right: 20px;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .container {
        max-width: 970px;
    }

    .feature-job__item {
        width: calc(100% / 3 - 14px) !important;
        float: left;
    }

    .feature-job__item:nth-child(3n + 1) {
        margin-right: 20px;
    }

    .feature-job__item:nth-child(3n + 2) {
        margin-right: 20px;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1170px;
    }

    //
    .feature-job__item {
        width: calc(100% / 3 - 14px) !important;
        float: left;
    }

    .feature-job__item:nth-child(3n + 1) {
        margin-right: 20px;
    }

    .feature-job__item:nth-child(3n + 2) {
        margin-right: 20px;
    }
}

.container {
    box-sizing: border-box;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.feature-job {
    box-sizing: content-box;
    padding: 24px 0;
    position: relative;
}

.feature-job__header {
    display: flex;
    padding: 0 0 12px;
    align-items: center;
    background: 0 0 !important;
    justify-content: space-between;

    .box-tool {
        display: flex;
        gap: 10px;
        align-items: center;
    }

    .box-tool * {
        box-sizing: border-box;
    }

    a {
        color: #172530;
        font-size: 13px;
        font-weight: 500;
        line-height: 19px;
        text-decoration-line: underline;
    }

    h2 {
        font-size: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 32px;
        color: #00b14f;
        margin: 0;
    }

    .btn {
        align-items: center;
        aspect-ratio: 1/1;
        border: 1px solid #00b14f;
        border-radius: 50%;
        color: #00b14f !important;
        cursor: pointer;
        display: flex;
        justify-content: center;
        width: 32px;
    }

    .deactive {
        border: 1px solid #dee0e2;
        color: #dee0e2 !important;
        cursor: unset;
        opacity: 1;
        pointer-events: none;
    }

    .btn:hover {
        background: #00b14f;
        color: #fff;

        .icon {
            color: #fff;
        }
    }

    .icon {
        font-size: 20px;
    }
}

.feature-job__filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: content-box;
    .filter {
        width: 270px;
        box-sizing: content-box;
    }
    .header-select {
        display: flex !important;
        height: 40px !important;
        align-items: center;
        width: 100%;
        background: #fff;
        border: 1px solid #dee0e2;
        border-radius: 6px;
        font-size: 14px;
        height: 40px;
        line-height: 20px;
        padding: 0 0px 0 10px;
        padding-right: 30px;
    }
    .group {
        align-items: center;
        color: #a6acb2;
        font-weight: 500;

        line-height: 20px;

        .icon-filter {
            color: #a6acb2;
            margin-right: 8px;
        }
    }

    .icon-chevron {
        box-sizing: content-box !important;
        font-size: 18px;
        padding-right: 10px;
        padding-left: 10px;
    }

    .ul-select {
        background: #fff;
        z-index: 2;

        .item {
            color: #172530 !important;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            padding: 12px 12px 12px 16px;
            cursor: pointer;

            .text {
                font-size: 14px;
                font-weight: 500;
                color: #172530;
            }

            .icon {
                font-size: 20px;
                color: #00b14f;
                position: absolute;
                right: 12px;
            }
        }

        .item:hover {
            background: #f4f5f5;
        }

        .item.active .text {
            color: #00b14f;
            font-weight: 600;
        }
    }

    .box-smart-location * {
        box-sizing: border-box;
    }
    .box-smart-location {
        .smart-location {
            display: flex;
            align-items: center;
        }

        .container-location {
            display: flex;
            gap: 10px;
            // align-items: center;
            cursor: pointer;
            overflow-x: scroll;
            overflow-y: hidden;
            position: relative;
            scrollbar-width: none;
            transition: all 0.2s;
            white-space: nowrap;
            width: 100%;
            margin: 0 10px;
            scroll-behavior: smooth;
        }
        .btn {
            align-items: center;
            cursor: pointer;
            display: flex;
            justify-content: center;
            box-sizing: border-box;
        }

        .btn-location {
            background: #e9eaec !important;
            border-radius: 20px;
            color: #212f3f !important;
            font-size: 14px !important;
            font-weight: 500;
            height: 40px;
            line-height: 20px;
            padding: 9px 12px !important;
            white-space: nowrap;
            border: 1px solid #fff;
        }
        .btn-icon {
            box-sizing: border-box;
            color: #00b14f !important;
            width: 32px;
            border-radius: 50%;
            aspect-ratio: 1/1;
            border: 1px solid #00b14f;
        }

        .deactive {
            border: 1px solid #dee0e2;
            color: #dee0e2 !important;
            cursor: unset;
            opacity: 1;
            pointer-events: none;
        }

        .btn-icon:hover {
            background: #00b14f;
            color: #fff;

            .icon {
                color: #fff;
            }
        }

        .btn-location:hover {
            border: 1px solid #00b14f;
            background: #fff !important;
        }
        .active {
            background: #00b14f !important;
            color: #fff !important;
        }

        .active:hover {
            background: #00b14f !important;
            color: #fff !important;
        }

        .icon {
            font-size: 20px;
        }
    }
}

.text-guide {
    display: flex;
    align-items: center;
    background: #f0f6ff;
    border: 1px solid #82afed;
    border-radius: 6px;
    gap: 6px;
    margin: 12px 0 0;
    padding: 6px 8px;
    position: relative;

    @keyframes fadeIn {
        0% {
            opacity: 0.2;
        }
        50% {
            opacity: 1;
        }
        100% {
            opacity: 0.2;
        }
    }
    .icon {
        margin-right: 4px;
        color: #82afed;
        font-size: 22px;
        animation: fadeIn 2s linear infinite;
    }

    .close-icon {
        cursor: pointer;
        position: absolute;
        right: 8px;
        font-size: 24px;
        color: #82afed;
    }

    p {
        font-feature-settings: 'clig' off, 'liga' off;
        color: #263a4d;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        letter-spacing: 0.14px;
        line-height: 16px;
        margin: 0 0 10px;
        margin-bottom: 0;
        // center
        display: flex;
        align-items: center;
        padding-right: 20px;
    }
}

.slick-slider {
    position: relative;
    display: block;
    margin-bottom: 0 !important;

    .visible {
        opacity: 1;
        visibility: visible;
    }
}

.slick-list {
    border-radius: 3px;
    transform: translate3d(0, 0, 0);
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.slick-track {
    // opacity: 0;
    // visibility: hidden;
    // opacity: 1;
    transition: opacity 0.5s ease;
    // width: 91200px;
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
    transform: translate3d(0, 0, 0);
}

.slick-slide {
    // width: 1140px;
    width: 100%;
    position: relative;
    left: 0px;
    top: 0px;
    z-index: 999;
    opacity: 1;
    float: left;
    height: 100%;
    min-height: 1px;
    padding: 2px 0 8px;
}

.feature-job__item {
    // margin: 0 0 0 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}
.feature-job__items {
    margin: 0 auto;
    width: 100%;
    // padding: 0 10px;
}

.feature-job__item {
    position: relative;
    min-height: 1px;
}

.feature-job__footer {
    margin: 0 auto;
    // width: 17%;

    .content-footer {
        align-items: center;
        display: flex;
        gap: 10px;
        justify-content: center;

        .btn {
            align-items: center;
            aspect-ratio: 1/1;
            border: 1px solid #00b14f;
            border-radius: 50%;
            color: #00b14f !important;
            cursor: pointer;
            display: flex;
            justify-content: center;
            width: 28px;
            height: 28px;
        }

        .deactive {
            border: 1px solid #dee0e2;
            color: #dee0e2 !important;
            cursor: unset;
            opacity: 1;
            pointer-events: none;
        }

        .btn:hover {
            background: #00b14f;
            color: #fff;

            .icon {
                color: #fff;
            }
        }

        .icon {
            font-size: 20px;
        }

        .number {
            color: #00b14f;
        }

        .text-page {
            color: #a6acb2;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
        }
    }
}

.large .feature-job__header {
    padding: 0 0 16px !important;
}

.large .box-smart-location {
    max-width: 74%;
}

.box-smart-location {
    max-width: 58%;
    min-width: 50%;
}

.medium .feature-job__item {
    width: calc(100% / 2 - 11px) !important;
    float: left;
}
.medium .feature-job__item:nth-child(2n + 1) {
    margin-right: 20px !important;
}

.small .feature-job__item {
    width: calc(100% / 2 - 11px) !important;
    float: left;
}
.small .feature-job__item:nth-child(2n + 1) {
    margin-right: 20px !important;
}
.small .feature-job__item:nth-child(2n) {
    margin-right: 0 !important;
    margin-left: 0 !important;
}

.extra-small .feature-job__item {
    width: 100% !important;
    float: none;
}
