.wrapper {
    border-radius: 5px;
    position: relative;
    min-height: 480px;
    background-color: #fff;
}

.box-info {
    padding: 16px 16px 0;
    .header-info {
        border-radius: 8px;
        background: linear-gradient(105deg, rgb(242, 251, 255) -1.43%, rgb(231, 239, 245) 100%);
        .info {
            position: relative;
            padding: 12px 16px 20px;
            display: flex;
            grid-gap: 14px;
            gap: 14px;

            .avatar {
                width: 56px;
                height: 56px;
                border: 1px solid #fff;
                border-radius: 50%;
            }

            .avatar-img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                object-fit: cover;
            }

            .employer-info {
                display: flex;
                grid-gap: 4px;
                gap: 4px;
                align-self: stretch;
                flex-direction: column;
                align-items: flex-start;
                color: rgb(0, 0, 0);
            }

            .employer-name {
                font-size: 16px;
                font-weight: 600;
                line-height: 20px;
                letter-spacing: -0.16px;
            }

            .employer-id {
                display: inline-block;
                background-color: rgb(232, 237, 242);
                border-radius: 4px;
                padding: 2px 8px;
                font-size: 12px;
                font-weight: 400;
                line-height: 16px;
                letter-spacing: 0.12px;
            }

            .employer-contact {
                margin-top: 4px;
                display: flex;
                align-items: flex-start;
                grid-gap: 12px;
                gap: 12px;
                font-size: 12px;
            }

            .employer-contact-item {
                display: flex;
                align-items: center;
                grid-gap: 8px;
                gap: 8px;
                font-weight: 400;
                line-height: 16px;
                letter-spacing: 0.12px;
            }

            .icon {
                font-size: 12px;
            }
        }
        .member {
            position: absolute;
            top: 10px;
            right: 0;
            border-radius: 20px 0 0 20px;
            background: linear-gradient(108deg, #e9f0f1 -7.66%, #d4d8d9 102.87%);
        }
    }

    .header-subtract {
        width: 100%;
        height: 24px;
        fill: #fff;
        filter: drop-shadow(0 -4px 20px rgba(0, 0, 0, 0.16));
        position: absolute;
        top: 66px;
        left: 0;

        .subtract {
            width: 100%;
            fill: #fff;
        }
    }
}

.box-content {
    display: flex;
    padding: 16px 16px 17px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    grid-gap: 16px;
    gap: 16px;
    position: absolute;
    width: 100%;
    background-color: #fff;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.employer-progress {
    width: 100%;
    min-height: 20px;
    margin-top: 45px;

    .progress {
        width: 100%;
        position: relative;
        margin-top: 5px;

        .custom-progress {
            width: 100%;
            appearance: none;
            cursor: pointer;
            background: linear-gradient(90deg, #c7e5e6, #f6fbf9);
            height: 8px;
            border-radius: 100px;
        }

        .progress-point {
            width: 100%;
            cursor: pointer;
            height: 8px;
            border-radius: 100px;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            .point {
                margin-top: -12px;
                position: relative;
            }
            .point-img {
                position: absolute;
                top: 0;
                left: -4px;
            }
        }

        .progress-text {
            position: absolute;
            width: 100%;
            top: -45px;

            .progress-list {
                display: flex;
            }

            .progress-item {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                grid-gap: 4px;
                gap: 4px;
                flex: 1 0 0;
                width: 10%;
            }

            .progress-item-ranking {
                color: #303235;
                font-size: 12px;
                font-weight: 600;
                line-height: 16px;
                letter-spacing: 0.15px;
            }

            .progress-item-point {
                color: #00b14f;
                font-size: 12px;
                font-weight: 600;
                line-height: 16px;
                letter-spacing: 0.15px;
            }
        }
    }

    .progress-more {
        width: 100%;
        margin-top: 17px;
        .progress-more-info {
            border: 1px solid #2ce175;
            border-radius: 8px;
            position: relative;
            background: #fff;
            padding: 16px 16px 0;
            display: flex;
            grid-gap: 8px;
            gap: 8px;
            flex-wrap: wrap;
            flex-direction: column;

            &::after {
                left: 6px;
                background: #fff;
                border-top: 1px solid #2ce175;
                border-left: 1px solid #2ce175;
                content: '';
                width: 10px;
                height: 10px;
                position: absolute;
                top: -5.5px;
                transform: rotate(45deg);
            }
            .progress-benefit {
                display: flex;
                align-self: stretch;
                flex: 1 0 0;
                justify-content: space-between;
                align-items: center;
                .now-point {
                    display: flex;
                    grid-gap: 4px;
                    gap: 4px;
                    flex-direction: column;
                    align-items: flex-start;
                }

                .now-point-label {
                    color: #5e6368;
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 16px;
                    letter-spacing: 0.15px;
                }

                .now-point-value {
                    display: flex;
                    grid-gap: 4px;
                    gap: 4px;
                    flex-direction: row;
                    align-items: center;

                    span {
                        color: #00b14f;
                        font-size: 20px;
                        font-weight: 700;
                        line-height: 28px;
                        letter-spacing: -0.2px;
                    }
                }

                .go-benefit {
                    display: flex;
                    height: 32px;
                    padding: 10px 16px;
                    justify-content: center;
                    align-items: center;
                    grid-gap: 10px;
                    gap: 10px;
                    border-radius: 4px;
                    background: #00b14f;
                    color: #fff;

                    &:hover {
                        background: #028b40;
                    }
                }

                .go-benefit-text {
                    font-size: 14px;
                    line-height: 1.5;
                    font-weight: 500;
                }

                .go-benefit-icon {
                    font-size: 14px;
                }
            }

            .progress-encourage {
                border-top: 1px solid #f5f8fa;
                padding-top: 12px;
                padding-bottom: 12px;
                .auth-verify {
                    display: flex;
                    align-items: center;
                    grid-gap: 16px;
                    gap: 16px;
                }

                .warning {
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px;
                    letter-spacing: 0.12px;
                }

                .warning-text {
                    color: #da4538;
                }

                .warning-more {
                    cursor: pointer;
                }

                .warning-link {
                    color: #007bff;
                    background-color: transparent;

                    &:hover {
                        color: #0056b3;
                        text-decoration: underline;
                    }
                }

                .verify-now {
                    padding: 6px 16px;
                    border-radius: 4px;
                    background: #e5f7ed;
                    min-width: 124px;
                    cursor: pointer;
                    color: #00b14f;
                    text-align: center;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 21px;
                    letter-spacing: 0.163px;

                    &:hover {
                        background: #d4f0e0;
                    }
                }
            }
        }
    }
}

.employer-detail {
    width: 100%;

    .employer-detail-content {
        display: flex;
        flex-wrap: wrap;

        .employer-detail-item {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            grid-gap: 9px;
            gap: 9px;
            flex: 1 0 0;
            align-self: stretch;

            .employer-detail-item-label {
                color: #303235;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: 150%;
                letter-spacing: 0.15px;
            }

            .employer-detail-item-value {
                display: flex;
                align-items: center;
                grid-gap: 8px;
                gap: 8px;
                align-self: stretch;
            }

            .ranking-point {
                display: flex;
                height: 24px;
                padding: 0 8px;
                justify-content: center;
                align-items: center;
                border-radius: 12px;
                background: #f5f8fa;
            }

            .ranking-point-value {
                color: #000;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: 16px;
                letter-spacing: 0.15px;
                margin-left: 4px;
            }

            .ranking-point-label {
                color: #5e6368;
                text-align: center;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                letter-spacing: 0.12px;
            }
        }
    }
}
