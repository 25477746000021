.wrapper {
}

.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(0, 192, 86, 0.16);
    border: 1px solid transparent;
    border-radius: 6px;
    gap: 12px;
    width: 100%;
}

.title {
}
