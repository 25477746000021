.wrapper {
    display: flex;
    align-items: center;
    border-radius: 8px;
    flex-direction: row;
    margin-bottom: 20px;
    padding: 23px 29px 23px 19px;
    position: relative;
    transition: 0.3s;
    background: #fff;
    border: 1px solid transparent;

    &:hover {
        border: 1px solid #33c172;
        cursor: pointer;
    }
}

@media screen and (max-width: 768px) {
    .wrapper {
        flex-direction: column !important;
    }

    .box-logo {
        margin-right: 0 !important;
    }

    .box-info {
        margin-right: 0 !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 24px;
    }

    .box-action {
        width: 100% !important;
        display: flex;
        justify-content: center;

        .btn-follow {
            width: 100% !important;
        }
    }
}

.box-logo {
    margin-right: 16px;

    .label {
        position: absolute;
        top: 8px;
        left: 12px;
        font-size: 10px;
        font-weight: 600;
        line-height: 15px;
        min-height: unset;
        padding: 2.5px 6px;
        border-bottom-left-radius: 3px;
        border-top-left-radius: 3px;
        color: #fff;
        margin-bottom: 8px;
        margin-right: auto;
        text-transform: uppercase;

        &::before {
            background: #fff;
            border-top-right-radius: 5px;
            content: '';
            height: 21px;
            position: absolute;
            right: -10px;
            top: 50%;
            transform: translateY(-50%) rotate(-45deg);
            width: 21px;
        }
    }

    .logo-link {
        width: 84px;
        height: 84px;
        display: block;
        .logo {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.box-info {
    flex-grow: 1;
    margin-right: 32px;
    text-align: left;

    .job-count {
        background: #f4f5f5;
        border-radius: 4px;
        color: #212f3f;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        margin-bottom: 0;
        padding: 4px 10px;
    }

    .name {
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        margin: 0 0 8px;
        text-align: left;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        color: #212f3f;
    }
}

.box-action {
    .btn-follow {
        flex-shrink: 0;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        margin-top: 0;
        padding: 9px 22px;
        width: 130px;
        background: #fff;
        border: 1px solid #99e0b9;
        border-radius: 6px;
        color: #00b14f;

        span {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &:hover {
            background: #e5f7ed;
            color: #00b14f;
        }
    }

    .icon {
        margin-right: 8px;
        color: #00b14f;
    }

    .followed {
        background: #fff;
        color: #212f3f;
    }
}

.vnr500 {
    background: linear-gradient(90deg, #fd7222, #edab28);
}

.fast500 {
    background: #243a76;
}

.profit500 {
    background: #dc2f2f;
}

.v1000 {
    background: #e48924;
}
