.wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 11px 0;
    gap: 24px;
}

@media screen and (max-width: 768px) {
    .wrapper {
        flex-direction: column;
    }

    .left-col {
        width: 100% !important;
    }

    .right-col {
        width: 100% !important;
    }

    .box-info {
        display: none !important;
    }
}

.box-path {
    width: 100%;
}

.left-col {
    width: 67%;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .box-header {
        padding: 20px 24px;
        border-radius: 8px;
        background: #fff;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 12px;

        .header-1 {
            width: 100%;
        }
        .header-2 {
            width: 60%;
        }

        .header-3 {
            width: 80%;
        }

        .header-4 {
            width: 40%;
        }
    }
}

.right-col {
    flex: calc(33% - 24px);
    display: flex;
    flex-direction: column;
    gap: 24px;

    .box-company {
        padding: 20px 24px;
        border-radius: 8px;
        background: #fff;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 12px;

        .box-company-header {
            width: 100%;
            display: flex;
            flex-direction: row;
            gap: 12px;
        }

        .box-company-header-right {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 12px;
        }

        .box-company-description {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 12px;
        }
    }

    .box-info {
        padding: 20px 24px;
        border-radius: 8px;
        background: #fff;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 12px;

        .box-info-header {
            width: 100%;
            display: flex;
            flex-direction: row;
            gap: 12px;
        }

        .box-info-header-right {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 12px;
        }

        .box-info-description {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 12px;
        }
    }
}
