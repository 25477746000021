.wrapper {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 11px 0;
    gap: 16px;
    width: 100%;
}

.wrapper.small {
    transform: scale(0.8);
}

.left-col {
    padding: 12px;
}

.right-col {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    flex: 1;

    .right-col-item {
        margin: 12px 20px 0 0;
        width: 100%;
    }

    .right-col-row {
        margin-top: 12px;
        display: flex;
        flex-direction: row;
        gap: 12px;
        width: 100%;
        justify-content: flex-start;
        height: 30px;

        .right-col-row-item {
            width: 30%;
        }

        .row-item {
            width: 100%;
        }
    }
}
